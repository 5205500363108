import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import './leaflet.measure.scss';
import './leaflet.measure.js';

function MeasureControl() {
  const map = useMap();

  useEffect(() => {
    const measureControl = new L.Control.Measure({
      position: 'bottomright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'hectares'
    });
    
    map.addControl(measureControl);
    return () => map.removeControl(measureControl);
  }, [map]);

  return null;
}

export default MeasureControl;