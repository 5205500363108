import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import { logout, useAuth, authFetch } from "../providers/authProvider";
import { decodeToken } from "react-jwt";

import "bulma/css/bulma.min.css";
import "react-responsive-modal/styles.css";
import "./Admin.scss";

// NATO icons
import ADML from "../assets/nato/ADML.png";
import RLS from "../assets/nato/RLS.png";
import SAM_Buk from "../assets/nato/SAM_Buk.png";
import SAM_Osa from "../assets/nato/SAM_Osa.png";
import SAM_S300 from "../assets/nato/SAM_S300.png";
import SAM_Tor from "../assets/nato/SAM_Tor.png";
import Tank from "../assets/nato/Tank.png";

// ICONS
import Icon001 from "../assets/icons/001.png"; // 9С18 «Купол»
import Icon002 from "../assets/icons/002.png"; // Бук-М3
import Icon003 from "../assets/icons/003.png"; // ЗРК Оса
import Icon004 from "../assets/icons/004.png"; // РЛС Гармон 1Л122-1Е
import Icon005 from "../assets/icons/005.png"; // РЛС Каста-2Е1
import Icon006 from "../assets/icons/006.png"; // РЛС КБС Зоопарк
import Icon007 from "../assets/icons/007.png"; // РЛС П-18
import Icon008 from "../assets/icons/008.png"; // С-200
import Icon009 from "../assets/icons/009.png"; // С-300В
import Icon010 from "../assets/icons/010.png"; // Тор м1
import Icon011 from "../assets/icons/011.png"; // Тор м2
import Icon012 from "../assets/icons/012.png"; // Тунгуска-М1
import Icon013 from "../assets/icons/013.png"; // C-500
import Icon014 from "../assets/icons/014.png"; // Панцир
import Icon015 from "../assets/icons/015.png"; // Бук
import Icon016 from "../assets/icons/016.png"; // Тор
import Icon017 from "../assets/icons/017.png"; // Стріла-10
import Icon018 from "../assets/icons/018.png"; // ЗСУ-23-4
import Icon019 from "../assets/icons/019.png"; // 64Н6Е2
import Icon020 from "../assets/icons/020.png"; // 30Н6Е2
import Icon021 from "../assets/icons/021.png"; // 96ЛЕ6
import Icon022 from "../assets/icons/022.png"; // 36Д6
import Icon023 from "../assets/icons/023.png"; // 1Т12М2
import Icon024 from "../assets/icons/024.png"; // 9S32
import Icon025 from "../assets/icons/025.png"; // C-400
import Icon026 from "../assets/icons/026.png"; // 5Н62В
import Icon027 from "../assets/icons/027.png"; // 50Н6А
import Icon028 from "../assets/icons/028.png"; // 91Н6
import Icon029 from "../assets/icons/029.png"; // С-75 Дубна и Волга
import Icon030 from "../assets/icons/030.png"; // С-300ПМ
import Icon031 from "../assets/icons/031.png"; // с-350
import Icon032 from "../assets/icons/032.png"; // 5Н62
import Icon033 from "../assets/icons/033.png"; // Панцирь С-2
import Icon34 from "../assets/icons/034.png"; // 1Л122-Е2 Гармонь
import Icon35 from "../assets/icons/035.png"; // 1Л277 Соболятник
import Icon36 from "../assets/icons/036.png"; // 1РЛ123Е Истра
import Icon37 from "../assets/icons/037.png"; // 9С18М Купол-М
import Icon38 from "../assets/icons/038.png"; // 29Б6 Контейнер
import Icon39 from "../assets/icons/039.png"; // 48Я6-К1 Подлет-К1
import Icon40 from "../assets/icons/040.png"; // 77Я6 Воронеж-М
import Icon41 from "../assets/icons/041.png"; // П-18-2 Прима
import Icon42 from "../assets/icons/042.png"; // РСП-27С
import Icon43 from "../assets/icons/043.png"; // 1Л122-1Е Гармонь
import Icon44 from "../assets/icons/044.png"; // 5П21 Подсолнух
import Icon45 from "../assets/icons/045.png"; // 55Ж6М Небо-М

function Admin() {
  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";

  const [logged, session] = useAuth();

  const [sam, setSam] = useState([]);
  const [rockets, setRockets] = useState([]);
  const [rls, setRls] = useState([]);

  const initialSAMData = {
    name: "",
    natoName: "",
    importName: "",
    defaultRocket: "",
    defaultRls: "",
    icon: "",
    iconNato: "",
    rlsList: [],
    rockets: [],
    systemFamily: "",
  };

  const initialMissileData = {
    name: "",
    natoName: "",
    importName: "",
    minRange: 0,
    maxRange: 0,
    speed: 0,
  };

  const initialRadarData = {
    name: "",
    natoName: "",
    importName: "",
    minRange: 0,
    maxRange: 0,
    icon: "",
    iconNato: "",
  };

  const initialUserData = {
    username: "",
    password: "",
    connectionDetails: "",
    role: "User",
    isPasswordUpdated: false,
  };

  const roles = [{
    label: "User",
    value: "User",
  }, {
    label: "Editor",
    value: "Editor",
  }, {
    label: "Admin",
    value: "Admin",
  }];

  const [samSingle, setSamSingle] = useState([]);
  const [rocketsSingle, setRocketsSingle] = useState(initialMissileData);
  const [rlsSingle, setRlsSingle] = useState(initialRadarData);

  const [openSAMAddModal, setOpenSAMAddModal] = useState(false);
  const [openRocketsAddModal, setOpenRocketsAddModal] = useState(false);
  const [openRLSAddModal, setOpenRLSAddModal] = useState(false);

  const [openSAMEditModal, setOpenSAMEditModal] = useState(false);
  const [openRocketsEditModal, setOpenRocketsEditModal] = useState(false);
  const [openRLSEditModal, setOpenRLSEditModal] = useState(false);

  const [openSAMDeleteModal, setOpenSAMDeleteModal] = useState(false);
  const [openRocketsDeleteModal, setOpenRocketsDeleteModal] = useState(false);
  const [openRLSDeleteModal, setOpenRLSDeleteModal] = useState(false);

  const [openEditSAMMissilesModal, setOpenEditSAMMissilesModal] =
    useState(false);
  const [openEditSAMRadarModal, setOpenEditSAMRadarModal] = useState(false);
  const [SAMMissileId, setSAMMissileId] = useState();
  const [SAMDefaultMissileId, setSAMDefaultMissileId] = useState();
  const [SAMRadarId, setSAMRadarId] = useState();
  const [SAMRadarDefaultId, setSAMRadarDefaultId] = useState();

  const [SAMid, setSAMid] = useState();
  const [Rocketsid, setRocketsid] = useState();
  const [RLSid, setRLSid] = useState();

  const [SAMname, setSAMname] = useState();
  const [Rocketsname, setRocketsname] = useState();
  const [RLSname, setRLSname] = useState();

  const [users, setUsers] = useState([]);
  const [openUsersAddModal, setOpenUsersAddModal] = useState(false);
  const [formDataForUsers, setFormDataForUsers] = useState(initialUserData);
  const [openUsersEditModal, setOpenUsersEditModal] = useState(false);
  const [openUsersDeleteModal, setOpenUsersDeleteModal] = useState(false);
  const [openSetUserRoleModal, setOpenSetUserRoleModal] = useState(false);
  const [userID, setUserID] = useState();
  const [userName, setUserName] = useState();
  const [userSingle, setUserSingle] = useState(initialUserData);
  const [newUserRole, setNewUserRole] = useState("User");

  const closeIcon = <i className="fa-solid fa-xmark"></i>;

  const tabOne = "one";
  const tabTwo = "two";
  const tabThree = "three";
  const [selectedTab, setSelectedTab] = useState(tabOne);
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const sectionOne = "section-one";
  const sectionTwo = "section-two";
  const [selectedSection, setSelectedSection] = useState(sectionOne);

  const [formDataForSAM, setFormDataForSAM] = useState(initialSAMData);
  const [formDataForMissile, setFormDataForMissile] =
    useState(initialMissileData);
  const [formDataForRadar, setFormDataForRadar] = useState(initialRadarData);

  const NATOIconsDropdownData = [
    { label: "ADML", value: ADML },
    { label: "RLS", value: RLS },
    { label: "SAM Buk", value: SAM_Buk },
    { label: "SAM Osa", value: SAM_Osa },
    { label: "SAM S300", value: SAM_S300 },
    { label: "SAM Tor", value: SAM_Tor },
    { label: "Tank", value: Tank },
  ];

  const IconsDropdownData = [
    { label: "9С18 «Купол»", value: Icon001 },
    { label: "Бук-М3", value: Icon002 },
    { label: "ЗРК Оса", value: Icon003 },
    { label: "РЛС Гармон 1Л122-1Е", value: Icon004 },
    { label: "РЛС Каста-2Е1", value: Icon005 },
    { label: "РЛС КБС Зоопарк", value: Icon006 },
    { label: "РЛС П-18", value: Icon007 },
    { label: "С-200", value: Icon008 },
    { label: "С-300В", value: Icon009 },
    { label: "Тор м1", value: Icon010 },
    { label: "Тор м2", value: Icon011 },
    { label: "Тунгуска-М1", value: Icon012 },
    { label: "C-500", value: Icon013 },
    { label: "Панцир", value: Icon014 },
    { label: "Бук", value: Icon015 },
    { label: "Тор", value: Icon016 },
    { label: "Стріла-10", value: Icon017 },
    { label: "ЗСУ-23-4", value: Icon018 },
    { label: "64Н6Е2", value: Icon019 },
    { label: "30Н6Е2", value: Icon020 },
    { label: "96ЛЕ6", value: Icon021 },
    { label: "36Д6", value: Icon022 },
    { label: "1Т12М2", value: Icon023 },
    { label: "9S32", value: Icon024 },
    { label: "C-400", value: Icon025 },
    { label: "5Н62В", value: Icon026 },
    { label: "50Н6А", value: Icon027 },
    { label: "91Н6", value: Icon028 },
    { label: "С-75 Дубна и Волга", value: Icon029 },
    { label: "С-300ПМ", value: Icon030 },
    { label: "с-350", value: Icon031 },
    { label: "5Н62", value: Icon032 },
    { label: "Панцирь С-2", value: Icon033 },
    { label: "1Л122-Е2 Гармонь", value: Icon34 },
    { label: "1Л277 Соболятник", value: Icon35 },
    { label: "1РЛ123Е Истра", value: Icon36 },
    { label: "9С18М Купол-М", value: Icon37 },
    { label: "29Б6 Контейнер", value: Icon38 },
    { label: "48Я6-К1 Подлет-К1", value: Icon39 },
    { label: "77Я6 Воронеж-М", value: Icon40 },
    { label: "П-18-2 Прима", value: Icon41 },
    { label: "РСП-27С", value: Icon42 },
    { label: "1Л122-1Е Гармонь", value: Icon43 },
    { label: "5П21 Подсолнух", value: Icon44 },
    { label: "55Ж6М Небо-М", value: Icon45 },
  ];

  useEffect(() => {
    document.title = "Admin";

    if (session) {
      loadSAMData();
      loadRocketsData();
      loadRLSData();
    }

    if (session && session.accessToken) {
      const decodedToken = decodeToken(session.accessToken);
      if (decodedToken) {
        setToken(decodedToken);
        setUserRole(decodedToken.role);
      }
    }
  }, [session]);

  // API calls START

  // GET
  function loadSAMData() {
    authFetch(`${API}/sam`, {})
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 401) {
          logout();
        }
        setSam(data);
        handleSuccessToast("Air Defense Systems data successfully loaded!");
      })
      .catch((error) => {
        handleFailedToast("Air Defense Systems data load failed!");
        console.error(error);
      });
  }

  function loadRocketsData() {
    authFetch(`${API}/rockets`, {})
      .then((response) => response.json())
      .then((data) => {
        setRockets(data);
        handleSuccessToast("Missiles data successfully loaded!");
      })
      .catch((error) => {
        handleFailedToast("Missiles data load failed!");
        console.error(error);
      });
  }

  function loadRLSData() {
    authFetch(`${API}/rls`, {})
      .then((response) => response.json())
      .then((data) => {
        setRls(data);
        handleSuccessToast("Radars data successfully loaded!");
      })
      .catch((error) => {
        handleFailedToast("Radars data load failed!");
        console.error(error);
      });
  }

  function loadUsers() {
    authFetch(`${API}/users`, {})
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        handleSuccessToast("Users data successfully loaded!");
      })
      .catch((error) => {
        handleFailedToast("Users data load failed!");
        console.error(error);
      });
  }

  // POST
  function addSAMData(data) {
    authFetch(`${API}/sam`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Air Defense Systems data successfully added!");
        setTimeout(() => {
          setFormDataForSAM(initialSAMData);
        }, 100);
        loadSAMData();
      })
      .catch((error) => {
        handleFailedToast("Air Defense Systems data add failed!");
        console.error(error);
      });
  }

  function addRocketsData(data) {
    authFetch(`${API}/rockets`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Missile data successfully added!");
        setTimeout(() => {
          setFormDataForMissile(initialMissileData);
        }, 100);
        loadRocketsData();
      })
      .catch((error) => {
        handleFailedToast("Missile data add failed!");
        console.error(error);
      });
  }

  function addRLSData(data) {
    authFetch(`${API}/rls`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Radar data successfully added!");
        setTimeout(() => {
          setFormDataForRadar(initialRadarData);
        }, 100);
        loadRLSData();
      })
      .catch((error) => {
        handleFailedToast("Radar data add failed!");
        console.error(error);
      });
  }

  function addSAMMissile(data) {
    authFetch(`${API}/sam/${SAMid}/rockets/${SAMMissileId}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Missile successfully added to SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMMissileId("");
      })
      .catch((error) => {
        handleFailedToast("Missile add failed!");
        console.error(error);
      });
  }

  function addSAMRadar(data) {
    authFetch(`${API}/sam/${SAMid}/rls/${SAMRadarId}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Radar successfully added to SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMRadarId("");
      })
      .catch((error) => {
        handleFailedToast("Radar add failed!");
        console.error(error);
      });
  }

  function addUsersData(data) {
    authFetch(`${API}/users`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("User successfully added!");
        setOpenUsersAddModal(false);
        loadUsers();
      })
      .catch((error) => {
        handleFailedToast("User add failed!");
        console.error(error);
      });
  }

  // PUT

  function addDefaultMissileForSAM(data) {
    authFetch(`${API}/sam/${SAMid}/defaultRocket/${SAMDefaultMissileId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Default missile successfully added to SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMDefaultMissileId("");
      })
      .catch((error) => {
        handleFailedToast("Default missile add failed!");
        console.error(error);
      });
  }

  function addDefaultRadarForSAM(data) {
    authFetch(`${API}/sam/${SAMid}/defaultRls/${SAMRadarDefaultId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Default radar successfully added to SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMRadarDefaultId("");
      })
      .catch((error) => {
        handleFailedToast("Default radar add failed!");
        console.error(error);
      });
  }

  function assignRoleToUser(data) {
    authFetch(`${API}/users/${userID}/assignRole/${data.role}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Role successfully assigned to User!");
        setOpenSetUserRoleModal(false);
        loadUsers();
      })
      .catch((error) => {
        handleFailedToast("Role assign failed!");
        console.error(error);
      });
  }

  // DELETE
  function deleteSAMData(id) {
    authFetch(`${API}/sam/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("Air Defense Systems data successfully deleted!");
        loadSAMData();
        setOpenSAMDeleteModal(false);
      })
      .catch((error) => {
        handleFailedToast("Air Defense Systems data delete failed!");
        console.error(error);
      });
  }

  function deleteRocketsData(id) {
    authFetch(`${API}/rockets/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("Missile data successfully deleted!");
        loadRocketsData();
        setOpenRocketsDeleteModal(false);
      })
      .catch((error) => {
        handleFailedToast("Missile data delete failed!");
        console.error(error);
      });
  }

  function deleteRLSData(id) {
    authFetch(`${API}/rls/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("Radar data successfully deleted!");
        loadRLSData();
        setOpenRLSDeleteModal(false);
      })
      .catch((error) => {
        handleFailedToast("Radar data delete failed!");
        console.error(error);
      });
  }

  function deleteSAMMissile(id) {
    authFetch(`${API}/sam/${SAMid}/rockets/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("Missile successfully deleted from SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMMissileId("");
      })
      .catch((error) => {
        handleFailedToast("Missile delete failed!");
        console.error(error);
      });
  }

  function deleteSAMRadar(id) {
    authFetch(`${API}/sam/${SAMid}/rls/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("Radar successfully deleted from SAM!");
        loadSAMData();
        getSAMDataById(SAMid);
        setSAMRadarId("");
      })
      .catch((error) => {
        handleFailedToast("Radar delete failed!");
        console.error(error);
      });
  }

  function deleteUser(id) {
    authFetch(`${API}/users/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        handleSuccessToast("User successfully deleted!");
        setOpenUsersDeleteModal(false);
        loadUsers();
      })
      .catch((error) => {
        handleFailedToast("User delete failed!");
        console.error(error);
      });
  }

  // GET by ID
  function getSAMDataById(id) {
    authFetch(`${API}/sam/${id}`, {})
      .then((response) => response.json())
      .then((data) => {
        setSamSingle(data);
      });
  }

  function getRocketsDataById(id) {
    authFetch(`${API}/rockets/${id}`, {})
      .then((response) => response.json())
      .then((data) => {
        setRocketsSingle(data);
      });
  }

  function getRLSDataById(id) {
    authFetch(`${API}/rls/${id}`, {})
      .then((response) => response.json())
      .then((data) => {
        setRlsSingle(data);
      });
  }

  function getUsersDataById(id) {
    authFetch(`${API}/users/${id}`, {})
      .then((response) => response.json())
      .then((data) => {
        setUserSingle(data);
      });
  }

  // PATCH
  function updateSAMData(id, data) {
    authFetch(`${API}/sam/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Air Defense Systems data successfully updated!");
        setTimeout(() => {
          setSamSingle(initialSAMData);
          setOpenSAMEditModal(false);
        }, 100);
        loadSAMData();
      })
      .catch((error) => {
        handleFailedToast("Air Defense Systems data update failed!");
        console.error(error);
      });
  }

  function updateRocketsData(id, data) {
    authFetch(`${API}/rockets/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Missile data successfully updated!");
        setTimeout(() => {
          setRocketsSingle(initialMissileData);
          setOpenRocketsEditModal(false);
        }, 100);
        loadRocketsData();
      })
      .catch((error) => {
        handleFailedToast("Missile data update failed!");
        console.error(error);
      });
  }

  function updateRLSData(id, data) {
    authFetch(`${API}/rls/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("Radar data successfully updated!");
        setTimeout(() => {
          setRlsSingle(initialRadarData);
          setOpenRLSEditModal(false);
        }, 100);
        loadRLSData();
      })
      .catch((error) => {
        handleFailedToast("Radar data update failed!");
        console.error(error);
      });
  }

  function updateUser(id, data) {
    authFetch(`${API}/users/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        handleSuccessToast("User successfully updated!");
        setOpenUsersEditModal(false);
        loadUsers();
      })
      .catch((error) => {
        handleFailedToast("User update failed!");
        console.error(error);
      });
  }

  // API calls END

  const handleSuccessToast = (message) => {
    toast.success(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        width: "250px",
      },
    });
  };

  const handleFailedToast = (message) => {
    toast.error(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        width: "250px",
      },
    });
  };

  const getIconByNatoName = (natoName) => {
    switch (natoName) {
      case "ADML":
        return ADML;
      case "RLS":
        return RLS;
      case "SAM Buk":
        return SAM_Buk;
      case "SAM Osa":
        return SAM_Osa;
      case "SAM S300":
        return SAM_S300;
      case "SAM Tor":
        return SAM_Tor;
      case "Tank":
        return Tank;
      default:
        return null;
    }
  };

  const getIconByIconName = (iconName) => {
    switch (iconName) {
      case "9С18 «Купол»":
        return Icon001;
      case "Бук-М3":
        return Icon002;
      case "ЗРК Оса":
        return Icon003;
      case "РЛС Гармон 1Л122-1Е":
        return Icon004;
      case "РЛС Каста-2Е1":
        return Icon005;
      case "РЛС КБС Зоопарк":
        return Icon006;
      case "РЛС П-18":
        return Icon007;
      case "С-200":
        return Icon008;
      case "С-300В":
        return Icon009;
      case "Тор м1":
        return Icon010;
      case "Тор м2":
        return Icon011;
      case "Тунгуска-М1":
        return Icon012;
      case "C-500":
        return Icon013;
      case "Панцир":
        return Icon014;
      case "Бук":
        return Icon015;
      case "Тор":
        return Icon016;
      case "Стріла-10":
        return Icon017;
      case "ЗСУ-23-4":
        return Icon018;
      case "64Н6Е2":
        return Icon019;
      case "30Н6Е2":
        return Icon020;
      case "96ЛЕ6":
        return Icon021;
      case "36Д6":
        return Icon022;
      case "1Т12М2":
        return Icon023;
      case "9S32":
        return Icon024;
      case "C-400":
        return Icon025;
      case "5Н62В":
        return Icon026;
      case "50Н6А":
        return Icon027;
      case "91Н6":
        return Icon028;
      case "С-75 Дубна и Волга":
        return Icon029;
      case "С-300ПМ":
        return Icon030;
      case "с-350":
        return Icon031;
      case "5Н62":
        return Icon032;
      case "Панцирь С-2":
        return Icon033;
      case "1Л122-Е2 Гармонь":
        return Icon34;
      case "1Л277 Соболятник":
        return Icon35;
      case "1РЛ123Е Истра":
        return Icon36;
      case "9С18М Купол-М":
        return Icon37;
      case "29Б6 Контейнер":
        return Icon38;
      case "48Я6-К1 Подлет-К1":
        return Icon39;
      case "77Я6 Воронеж-М":
        return Icon40;
      case "П-18-2 Прима":
        return Icon41;
      case "РСП-27С":
        return Icon42;
      case "1Л122-1Е Гармонь":
        return Icon43;
      case "5П21 Подсолнух":
        return Icon44;
      case "55Ж6М Небо-М":
        return Icon45;
      default:
        return null;
    }
  };

  const SAMTable = () => {
    return (
      <>
        {sam.map((item) => (
          <tr key={item.id}>
            <th title={item.name}>{item.name}</th>
            <td title={item.natoName}>{item.natoName}</td>
            <td>
              {item.rockets?.length}{" "}
              <button
                className="button is-link is-light is-small ml-3"
                onClick={() => {
                  setSAMid(item.id);
                  setSAMname(item.name);
                  getSAMDataById(item.id);
                  setOpenEditSAMMissilesModal(true);
                }}
              >
                Edit
              </button>
            </td>
            <td>
              {item.rlsList?.length}{" "}
              <button
                className="button is-link is-light is-small ml-3"
                onClick={() => {
                  setSAMid(item.id);
                  setSAMname(item.name);
                  getSAMDataById(item.id);
                  setOpenEditSAMRadarModal(true);
                }}
              >
                Edit
              </button>
            </td>
            <td>
              <img
                src={getIconByIconName(item.icon)}
                className="icon-nato-table"
              />
            </td>
            <td>
              <img
                src={getIconByNatoName(item.iconNato)}
                className="icon-nato-table"
              />
            </td>
            <td>
              <div className="buttons is-right">
                <button
                  className="button is-success is-light is-small"
                  onClick={() => {
                    getSAMDataById(item.id);
                    setOpenSAMEditModal(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="button is-danger is-light is-small"
                  onClick={() => {
                    setOpenSAMDeleteModal(true);
                    setSAMid(item.id);
                    setSAMname(item.name);
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const RocketsTable = () => {
    return (
      <>
        {rockets.map((item) => (
          <tr key={item.id}>
            <th title={item.name}>{item.name}</th>
            <td title={item.natoName}>{item.natoName}</td>
            <td>{Number(item.minRange).toLocaleString()}</td>
            <td>{Number(item.maxRange).toLocaleString()}</td>
            <td>{item.speed}</td>
            <td>
              <div className="buttons is-right">
                <button
                  className="button is-success is-light is-small"
                  onClick={() => {
                    getRocketsDataById(item.id);
                    setOpenRocketsEditModal(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="button is-danger is-light is-small"
                  onClick={() => {
                    setOpenRocketsDeleteModal(true);
                    setRocketsid(item.id);
                    setRocketsname(item.name);
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const RLSTable = () => {
    return (
      <>
        {rls.map((item) => (
          <tr key={item.id}>
            <th title={item.name}>{item.name}</th>
            <td title={item.natoName}>{item.natoName}</td>
            <td>{Number(item.minRange).toLocaleString()}</td>
            <td>{Number(item.maxRange).toLocaleString()}</td>
            <td>
              <img
                src={getIconByIconName(item.icon)}
                className="icon-nato-table"
              />
            </td>
            <td>
              <img
                src={getIconByNatoName(item.iconNato)}
                className="icon-nato-table"
              />
            </td>
            <td>
              <div className="buttons is-right">
                <button
                  className="button is-success is-light is-small"
                  onClick={() => {
                    getRLSDataById(item.id);
                    setOpenRLSEditModal(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="button is-danger is-light is-small"
                  onClick={() => {
                    setOpenRLSDeleteModal(true);
                    setRLSid(item.id);
                    setRLSname(item.name);
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const UsersTable = () => {
    return (
      <>
        {users.map((item) => (
          <tr key={item.id}>
            <th title={item.username}>{item.username}</th>
            <td title={item.role}>
              {item.role}
              <button
                className="button is-link is-light is-small ml-3"
                onClick={() => {
                  setUserID(item.id);
                  setOpenSetUserRoleModal(true);
                }}
              >
                Edit
              </button>
              </td>
            <td title={item.connectionDetails || "-"}>
              {item.connectionDetails || "-"}
            </td>
            <td title={item.isPasswordUpdated.toString()}>
              {item.isPasswordUpdated.toString()}
            </td>

            <td>
              <div className="buttons is-right">
                <button
                  className="button is-success is-light is-small"
                  onClick={() => {
                    setOpenUsersEditModal(true);
                    getUsersDataById(item.id);
                  }}
                >
                  Edit
                </button>
                <button
                  className="button is-danger is-light is-small"
                  onClick={() => {
                    setOpenUsersDeleteModal(true);
                    setUserID(item.id);
                    setUserName(item.username);
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  // Form handlers for Users

  const handleSubmitUsersForm = (event) => {
    event.preventDefault();
    addUsersData({
      ...formDataForUsers,
    });
  };

  const handleUsersFormChange = (event) => {
    const { name, value } = event.target;
    setFormDataForUsers((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Edit

  const handleSubmitUsersEditForm = (event) => {
    event.preventDefault();
    updateUser(userSingle.id, {
      ...userSingle,
    });
  };

  const handleUsersEditFormChange = (event) => {
    const { name, value } = event.target;
    setUserSingle((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSetUserRole = (event) => {
    event.preventDefault();
    assignRoleToUser({
      role: newUserRole,
    });
  }

  const handleUserRoleChange = (event) => {
    const { value } = event.target;
    setNewUserRole(value);
  };

  // Form handlers for SAM

  const handleSubmitSAMForm = (event) => {
    event.preventDefault();
    addSAMData({
      ...formDataForSAM,
    });
  };

  const handleSAMFormChange = (event) => {
    const { name, value } = event.target;
    setFormDataForSAM((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Edit

  const handleSubmitSAMEditForm = (event) => {
    event.preventDefault();
    updateSAMData(samSingle.id, {
      ...samSingle,
    });
  };

  const handleSAMEditFormChange = (event) => {
    const { name, value } = event.target;
    setSamSingle((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Add missile to SAM
  const handleAddMissileToSAMForm = (event) => {
    event.preventDefault();
    addSAMMissile({
      missileId: SAMMissileId,
    });
  };

  // Remove missile from SAM
  const handleRemoveMissileFromSAM = (id) => {
    deleteSAMMissile(id);
  };

  // Set default missile for SAM
  const handleSetDefaultMissileForSAMForm = (event) => {
    event.preventDefault();
    addDefaultMissileForSAM({
      missileId: SAMDefaultMissileId,
    });
  };

  // Add radar to SAM
  const handleAddRadarToSAMForm = (event) => {
    event.preventDefault();
    addSAMRadar({
      radarId: SAMRadarId,
    });
  };

  // Remove radar from SAM
  const handleRemoveRadarFromSAM = (id) => {
    deleteSAMRadar(id);
  };

  // Set default radar for SAM
  const handleSetDefaultRadarForSAMForm = (event) => {
    event.preventDefault();
    addDefaultRadarForSAM({
      radarId: SAMRadarDefaultId,
    });
  };

  // Form handlers for Missiles
  const handleSubmitMissileForm = (event) => {
    event.preventDefault();
    addRocketsData({
      ...formDataForMissile,
      minRange: Number(formDataForMissile.minRange),
      maxRange: Number(formDataForMissile.maxRange),
      speed: Number(formDataForMissile.speed),
    });
  };

  const handleMissileFormChange = (event) => {
    const { name, value } = event.target;
    setFormDataForMissile((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Edit

  const handleSubmitMissileEditForm = (event) => {
    event.preventDefault();
    updateRocketsData(rocketsSingle.id, {
      ...rocketsSingle,
      minRange: Number(rocketsSingle.minRange),
      maxRange: Number(rocketsSingle.maxRange),
      speed: Number(rocketsSingle.speed),
    });
  };

  const handleMissileEditFormChange = (event) => {
    const { name, value } = event.target;
    setRocketsSingle((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form handlers for Radar
  const handleSubmitRadarForm = (event) => {
    event.preventDefault();
    addRLSData({
      ...formDataForRadar,
      minRange: Number(formDataForRadar.minRange),
      maxRange: Number(formDataForRadar.maxRange),
    });
  };

  const handleRadarFormChange = (event) => {
    const { name, value } = event.target;
    setFormDataForRadar((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Edit
  const handleSubmitRadarEditForm = (event) => {
    event.preventDefault();
    updateRLSData(rlsSingle.id, {
      ...rlsSingle,
      minRange: Number(rlsSingle.minRange),
      maxRange: Number(rlsSingle.maxRange),
    });
  };

  const handleRadarEditFormChange = (event) => {
    const { name, value } = event.target;
    setRlsSingle((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="Admin">
      <div className="container pt-5">
        <div className="box has-background-white">
          <div className="columns">
            <div className="column is-one-third">
              <h4 className="subtitle is-4 mt-2">Admin Panel</h4>
            </div>
            <div className="column is-one-third">
              {userRole === "Admin" && (
                <div className="tabs tabs-admin is-toggle">
                  <ul>
                    <li
                      className={
                        selectedSection === sectionOne ? "is-active" : ""
                      }
                      onClick={() => setSelectedSection(sectionOne)}
                    >
                      <a>
                        <span className="icon is-small">
                          <i className="fa-solid fa-map-location-dot mr-2"></i>
                        </span>
                        <span>Map Data</span>
                      </a>
                    </li>
                    <li
                      className={
                        selectedSection === sectionTwo ? "is-active" : ""
                      }
                      onClick={() => {setSelectedSection(sectionTwo); loadUsers()}}
                    >
                      <a>
                        <span className="icon is-small">
                          <i className="fa-solid fa-users mr-2"></i>
                        </span>
                        <span>Users</span>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="column is-one-third">
              <div className="buttons is-right">
                <a href="/">
                  <button className="button is-info is-light">
                    Go to map <i className="fa-solid fa-chevron-right ml-5"></i>
                  </button>
                </a>
                <button
                  className="button is-white has-text-danger pt-3 pb-3"
                  onClick={() => logout()}
                  title="Log Out"
                >
                  <i className="fa-solid fa-right-from-bracket"></i>
                </button>
              </div>
            </div>
          </div>

          {selectedSection === sectionOne && (
            <>
              <div className="tabs tabs-table is-centered">
                <ul>
                  <li
                    className={selectedTab === tabOne ? "is-active" : ""}
                    onClick={() => setSelectedTab(tabOne)}
                  >
                    <a href="#">Air Defense Systems \ ЗРК Комплекс</a>
                  </li>
                  <li
                    className={selectedTab === tabTwo ? "is-active" : ""}
                    onClick={() => setSelectedTab(tabTwo)}
                  >
                    <a href="#">Missiles \ Ракети</a>
                  </li>
                  <li
                    className={selectedTab === tabThree ? "is-active" : ""}
                    onClick={() => setSelectedTab(tabThree)}
                  >
                    <a href="#">Radars \ РЛС</a>
                  </li>
                </ul>
              </div>

              {selectedTab === tabOne && (
                <>
                  <div className="buttons mb-5 mt-2">
                    <button
                      className="button is-success is-outlined"
                      onClick={() => setOpenSAMAddModal(true)}
                    >
                      <i className="fa-solid fa-plus mr-4"></i> Add new
                    </button>
                    <Modal
                      open={openSAMAddModal}
                      onClose={() => setOpenSAMAddModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Add new <strong>ADS</strong>
                      </h5>

                      <form onSubmit={handleSubmitSAMForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter SAM name"
                              value={formDataForSAM.name}
                              onChange={handleSAMFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter SAM NATO name"
                              value={formDataForSAM.natoName}
                              onChange={handleSAMFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter SAM Import Name"
                              value={formDataForSAM.importName}
                              onChange={handleSAMFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">
                            Modification of / Original Name
                          </label>
                          <div className="control">
                            <input
                              name="systemFamily"
                              className="input"
                              type="text"
                              placeholder="Enter Original Name Name"
                              value={formDataForSAM.systemFamily}
                              onChange={handleSAMFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="columns">
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="icon"
                                    value={formDataForSAM.icon}
                                    onChange={handleSAMFormChange}
                                  >
                                    <option>Select icon</option>
                                    {IconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {formDataForSAM.icon && (
                                  <img
                                    src={getIconByIconName(formDataForSAM.icon)}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">NATO Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="iconNato"
                                    value={formDataForSAM.iconNato}
                                    onChange={handleSAMFormChange}
                                  >
                                    <option>Select NATO icon</option>
                                    {NATOIconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {formDataForSAM.iconNato && (
                                  <img
                                    src={getIconByNatoName(
                                      formDataForSAM.iconNato
                                    )}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-success has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Add new SAM
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openSAMEditModal}
                      onClose={() => setOpenSAMEditModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Edit <strong>{samSingle.name}</strong>
                      </h5>

                      <form onSubmit={handleSubmitSAMEditForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter SAM name"
                              value={samSingle.name}
                              onChange={handleSAMEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter SAM NATO name"
                              value={samSingle.natoName}
                              onChange={handleSAMEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter SAM Import Name"
                              value={samSingle.importName}
                              onChange={handleSAMEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">
                            Modification of / Original Name
                          </label>
                          <div className="control">
                            <input
                              name="systemFamily"
                              className="input"
                              type="text"
                              placeholder="Enter Original Name Name"
                              value={samSingle.systemFamily}
                              onChange={handleSAMEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="columns">
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="icon"
                                    value={samSingle.icon}
                                    onChange={handleSAMEditFormChange}
                                  >
                                    <option>Select icon</option>
                                    {IconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {samSingle.icon && (
                                  <img
                                    src={getIconByIconName(samSingle.icon)}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">NATO Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="iconNato"
                                    value={samSingle.iconNato}
                                    onChange={handleSAMEditFormChange}
                                  >
                                    <option>Select NATO icon</option>
                                    {NATOIconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {samSingle.iconNato && (
                                  <img
                                    src={getIconByNatoName(samSingle.iconNato)}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-info has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Update SAM
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openSAMDeleteModal}
                      onClose={() => setOpenSAMDeleteModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Are you sure?
                      </h5>

                      <div className="mt-5 mb-5">
                        You want to delete: <strong>{SAMname}</strong>
                      </div>

                      <div className="field is-grouped is-grouped-right mb-0">
                        <div className="control">
                          <button
                            className="button is-danger has-text-white"
                            onClick={() => deleteSAMData(SAMid)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      open={openEditSAMMissilesModal}
                      onClose={() => setOpenEditSAMMissilesModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Edit Missiles for <strong>{SAMname}</strong>
                      </h5>

                      <h5 className="subtitle is-5 color-blue">
                        Add new missile
                      </h5>
                      <form onSubmit={handleAddMissileToSAMForm}>
                        <div className="columns">
                          <div className="column is-two-thirds">
                            <div className="field">
                              <div className="control">
                                <div className="select is-fullwidth">
                                  <select
                                    onChange={(e) =>
                                      setSAMMissileId(e.target.value)
                                    }
                                  >
                                    <option>Select missile</option>
                                    {rockets.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <button
                              className="button is-success has-text-white pr-5 pl-5 is-fullwidth"
                              type="submit"
                              disabled={!SAMMissileId}
                            >
                              Add missile
                            </button>
                          </div>
                        </div>
                      </form>

                      <div className="line"></div>
                      <h5 className="subtitle is-5 color-blue">
                        Set default missile
                      </h5>
                      <form onSubmit={handleSetDefaultMissileForSAMForm}>
                        {samSingle.rockets && (
                          <div className="columns">
                            <div className="column is-two-thirds">
                              <div className="field">
                                <div className="control">
                                  <div className="select is-fullwidth">
                                    <select
                                      onChange={(e) =>
                                        setSAMDefaultMissileId(e.target.value)
                                      }
                                    >
                                      <option>Select default missile</option>
                                      {samSingle.rockets.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="column">
                              <div className="control">
                                <button
                                  className="button is-primary has-text-white pr-5 pl-5 is-fullwidth"
                                  type="submit"
                                  disabled={!SAMDefaultMissileId}
                                >
                                  Set default missile
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </form>

                      <div className="line"></div>
                      <h5 className="subtitle is-5 color-blue">
                        List of assigned missiles
                      </h5>

                      <table className="table is-fullwidth is-hoverable">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>
                              <span className="nato-color">NATO name</span>
                            </th>
                            <th>Max. Range (m)</th>
                            <th>Speed (m/s)</th>
                            <th className="text-right">Actions</th>
                          </tr>
                        </thead>
                        {samSingle.rockets && (
                          <tbody>
                            {samSingle.rockets?.map((item) => (
                              <tr key={item.id}>
                                <th title={item.name}>{item.name}</th>
                                <td title={item.natoName}>{item.natoName}</td>
                                <td>
                                  {Number(item.maxRange).toLocaleString()}
                                </td>
                                <td>{item.speed}</td>
                                <td>
                                  <div className="buttons is-right">
                                    <button
                                      className="button is-danger is-light is-small"
                                      onClick={() => {
                                        handleRemoveMissileFromSAM(item.id);
                                      }}
                                    >
                                      Unassign
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </Modal>

                    <Modal
                      open={openEditSAMRadarModal}
                      onClose={() => setOpenEditSAMRadarModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Edit Radars for <strong>{SAMname}</strong>
                      </h5>

                      <h5 className="subtitle is-5 color-blue">
                        Add new Radar
                      </h5>
                      <form onSubmit={handleAddRadarToSAMForm}>
                        <div className="columns">
                          <div className="column is-two-thirds">
                            <div className="field">
                              <div className="control">
                                <div className="select is-fullwidth">
                                  <select
                                    onChange={(e) =>
                                      setSAMRadarId(e.target.value)
                                    }
                                  >
                                    <option>Select radar</option>
                                    {rls.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <button
                              className="button is-success has-text-white pr-5 pl-5 is-fullwidth"
                              type="submit"
                              disabled={!SAMRadarId}
                            >
                              Add radar
                            </button>
                          </div>
                        </div>
                      </form>

                      <div className="line"></div>
                      <h5 className="subtitle is-5 color-blue">
                        Set default radar
                      </h5>
                      <form onSubmit={handleSetDefaultRadarForSAMForm}>
                        {samSingle.rlsList && (
                          <div className="columns">
                            <div className="column is-two-thirds">
                              <div className="field">
                                <div className="control">
                                  <div className="select is-fullwidth">
                                    <select
                                      onChange={(e) =>
                                        setSAMRadarDefaultId(e.target.value)
                                      }
                                    >
                                      <option>Select default radar</option>
                                      {samSingle.rlsList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="column">
                              <div className="control">
                                <button
                                  className="button is-primary has-text-white pr-5 pl-5 is-fullwidth"
                                  type="submit"
                                  disabled={!SAMRadarDefaultId}
                                >
                                  Set default radar
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </form>

                      <div className="line"></div>
                      <h5 className="subtitle is-5 color-blue">
                        List of assigned radars
                      </h5>

                      <table className="table is-fullwidth is-hoverable">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>
                              <span className="nato-color">NATO name</span>
                            </th>
                            <th>Max. Range (m)</th>
                            <th className="text-right">Actions</th>
                          </tr>
                        </thead>
                        {samSingle.rlsList && (
                          <tbody>
                            {samSingle.rlsList?.map((item) => (
                              <tr key={item.id}>
                                <th title={item.name}>{item.name}</th>
                                <td title={item.natoName}>{item.natoName}</td>
                                <td>
                                  {Number(item.maxRange).toLocaleString()}
                                </td>
                                <td>
                                  <div className="buttons is-right">
                                    <button
                                      className="button is-danger is-light is-small"
                                      onClick={() => {
                                        handleRemoveRadarFromSAM(item.id);
                                      }}
                                    >
                                      Unassign
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </Modal>
                  </div>

                  <table className="table is-fullwidth is-hoverable is-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>
                          <span className="nato-color">NATO name</span>
                        </th>
                        <th>Missiles</th>
                        <th>Radars</th>
                        <th>Icon</th>
                        <th>
                          <span className="nato-color">NATO icon</span>
                        </th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <SAMTable />
                    </tbody>
                  </table>
                </>
              )}

              {selectedTab === tabTwo && (
                <>
                  <div className="buttons mb-5 mt-2">
                    <button
                      className="button is-success is-outlined"
                      onClick={() => setOpenRocketsAddModal(true)}
                    >
                      <i className="fa-solid fa-plus mr-4"></i> Add new
                    </button>

                    <Modal
                      open={openRocketsAddModal}
                      onClose={() => setOpenRocketsAddModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Add new <strong>Missile</strong>
                      </h5>

                      <form onSubmit={handleSubmitMissileForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter missile name"
                              value={formDataForMissile.name}
                              onChange={handleMissileFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter missile NATO name"
                              value={formDataForMissile.natoName}
                              onChange={handleMissileFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter missile Import Name"
                              value={formDataForMissile.importName}
                              onChange={handleMissileFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Min. range (m)</label>
                          <div className="control">
                            <input
                              name="minRange"
                              className="input"
                              type="number"
                              placeholder="Enter min. range"
                              value={formDataForMissile.minRange}
                              onChange={handleMissileFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Max. range (m)</label>
                          <div className="control">
                            <input
                              name="maxRange"
                              className="input"
                              type="number"
                              placeholder="Enter max. range"
                              value={formDataForMissile.maxRange}
                              onChange={handleMissileFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Speed (m/s)</label>
                          <div className="control">
                            <input
                              name="speed"
                              className="input"
                              type="number"
                              placeholder="Enter missile speed"
                              value={formDataForMissile.speed}
                              onChange={handleMissileFormChange}
                            />
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-success has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Add new missile
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openRocketsEditModal}
                      onClose={() => setOpenRocketsEditModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Edit <strong>{rocketsSingle.name}</strong>
                      </h5>

                      <form onSubmit={handleSubmitMissileEditForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter missile name"
                              value={rocketsSingle.name}
                              onChange={handleMissileEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter missile NATO name"
                              value={rocketsSingle.natoName}
                              onChange={handleMissileEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter missile Import Name"
                              value={rocketsSingle.importName}
                              onChange={handleMissileEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Min. range (m)</label>
                          <div className="control">
                            <input
                              name="minRange"
                              className="input"
                              type="number"
                              placeholder="Enter min. range"
                              value={rocketsSingle.minRange}
                              onChange={handleMissileEditFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Max. range (m)</label>
                          <div className="control">
                            <input
                              name="maxRange"
                              className="input"
                              type="number"
                              placeholder="Enter max. range"
                              value={rocketsSingle.maxRange}
                              onChange={handleMissileEditFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Speed (m/s)</label>
                          <div className="control">
                            <input
                              name="speed"
                              className="input"
                              type="number"
                              placeholder="Enter missile speed"
                              value={rocketsSingle.speed}
                              onChange={handleMissileEditFormChange}
                            />
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-info has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Update missile
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openRocketsDeleteModal}
                      onClose={() => setOpenRocketsDeleteModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Are you sure?
                      </h5>

                      <div className="mt-5 mb-5">
                        You want to delete: <strong>{Rocketsname}</strong>
                      </div>

                      <div className="field is-grouped is-grouped-right mb-0">
                        <div className="control">
                          <button
                            className="button is-danger has-text-white"
                            onClick={() => deleteRocketsData(Rocketsid)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  <table className="table is-fullwidth is-hoverable is-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>
                          <span className="nato-color">NATO name</span>
                        </th>
                        <th>Min. range (m)</th>
                        <th>Max. range (m)</th>
                        <th>Speed (m/s)</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <RocketsTable />
                    </tbody>
                  </table>
                </>
              )}

              {selectedTab === tabThree && (
                <>
                  <div className="buttons mb-5 mt-2">
                    <button
                      className="button is-success is-outlined"
                      onClick={() => setOpenRLSAddModal(true)}
                    >
                      <i className="fa-solid fa-plus mr-4"></i> Add new
                    </button>

                    <Modal
                      open={openRLSAddModal}
                      onClose={() => setOpenRLSAddModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Add new <strong>Radar</strong>
                      </h5>

                      <form onSubmit={handleSubmitRadarForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter radar name"
                              value={formDataForRadar.name}
                              onChange={handleRadarFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter radar NATO name"
                              value={formDataForRadar.natoName}
                              onChange={handleRadarFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter radar Import Name"
                              value={formDataForRadar.importName}
                              onChange={handleRadarFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Min. range (m)</label>
                          <div className="control">
                            <input
                              name="minRange"
                              className="input"
                              type="number"
                              placeholder="Enter min. range"
                              value={formDataForRadar.minRange}
                              onChange={handleRadarFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Max. range (m)</label>
                          <div className="control">
                            <input
                              name="maxRange"
                              className="input"
                              type="number"
                              placeholder="Enter max. range"
                              value={formDataForRadar.maxRange}
                              onChange={handleRadarFormChange}
                            />
                          </div>
                        </div>

                        <div className="columns">
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="icon"
                                    value={formDataForRadar.icon}
                                    onChange={handleRadarFormChange}
                                  >
                                    <option>Select icon</option>
                                    {IconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {formDataForRadar.icon && (
                                  <img
                                    src={getIconByIconName(
                                      formDataForRadar.icon
                                    )}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">NATO Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="iconNato"
                                    value={formDataForRadar.iconNato}
                                    onChange={handleRadarFormChange}
                                  >
                                    <option>Select NATO icon</option>
                                    {NATOIconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {formDataForRadar.iconNato && (
                                  <img
                                    src={getIconByNatoName(
                                      formDataForRadar.iconNato
                                    )}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-success has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Add new radar
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openRLSEditModal}
                      onClose={() => setOpenRLSEditModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Edit <strong>{rlsSingle.name}</strong>
                      </h5>

                      <form onSubmit={handleSubmitRadarEditForm}>
                        <div className="field">
                          <label className="label">Name</label>
                          <div className="control">
                            <input
                              name="name"
                              className="input"
                              type="text"
                              placeholder="Enter radar name"
                              value={rlsSingle.name}
                              onChange={handleRadarEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">NATO Name</label>
                          <div className="control">
                            <input
                              name="natoName"
                              className="input"
                              type="text"
                              placeholder="Enter radar NATO name"
                              value={rlsSingle.natoName}
                              onChange={handleRadarEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Import Name</label>
                          <div className="control">
                            <input
                              name="importName"
                              className="input"
                              type="text"
                              placeholder="Enter radar Import Name"
                              value={rlsSingle.importName}
                              onChange={handleRadarEditFormChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Min. range (m)</label>
                          <div className="control">
                            <input
                              name="minRange"
                              className="input"
                              type="number"
                              placeholder="Enter min. range"
                              value={rlsSingle.minRange}
                              onChange={handleRadarEditFormChange}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label">Max. range (m)</label>
                          <div className="control">
                            <input
                              name="maxRange"
                              className="input"
                              type="number"
                              placeholder="Enter max. range"
                              value={rlsSingle.maxRange}
                              onChange={handleRadarEditFormChange}
                            />
                          </div>
                        </div>

                        <div className="columns">
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="icon"
                                    value={rlsSingle.icon}
                                    onChange={handleRadarEditFormChange}
                                  >
                                    <option>Select icon</option>
                                    {IconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {rlsSingle.icon && (
                                  <img
                                    src={getIconByIconName(rlsSingle.icon)}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field icon-preview-field">
                              <label className="label">NATO Icon</label>
                              <div className="control">
                                <div className="select">
                                  <select
                                    name="iconNato"
                                    value={rlsSingle.iconNato}
                                    onChange={handleRadarEditFormChange}
                                  >
                                    <option>Select NATO icon</option>
                                    {NATOIconsDropdownData.map((item) => (
                                      <option
                                        key={item.label}
                                        value={item.label}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {rlsSingle.iconNato && (
                                  <img
                                    src={getIconByNatoName(rlsSingle.iconNato)}
                                    className="icon-nato"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field is-grouped is-grouped-right">
                          <div className="control">
                            <button
                              className="button is-info has-text-white pr-5 pl-5 mt-3"
                              type="submit"
                            >
                              Update radar
                            </button>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    <Modal
                      open={openRLSDeleteModal}
                      onClose={() => setOpenRLSDeleteModal(false)}
                      closeOnOverlayClick={false}
                      closeIcon={closeIcon}
                    >
                      <h5 className="subtitle is-5 modal-title">
                        Are you sure?
                      </h5>

                      <div className="mt-5 mb-5">
                        You want to delete: <strong>{RLSname}</strong>
                      </div>

                      <div className="field is-grouped is-grouped-right mb-0">
                        <div className="control">
                          <button
                            className="button is-danger has-text-white"
                            onClick={() => deleteRLSData(RLSid)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  <table className="table is-fullwidth is-hoverable is-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>
                          <span className="nato-color">NATO name</span>
                        </th>
                        <th>Min. range (m)</th>
                        <th>Max. range (m)</th>
                        <th>Icon</th>
                        <th>
                          <span className="nato-color">NATO icon</span>
                        </th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <RLSTable />
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
          {selectedSection === sectionTwo && (
            <>
              <div className="buttons mb-5 mt-2">
                <button
                  className="button is-success is-outlined"
                  onClick={() => setOpenUsersAddModal(true)}
                >
                  <i className="fa-solid fa-plus mr-4"></i> Add new
                </button>
              </div>

              <Modal
                open={openUsersAddModal}
                onClose={() => setOpenUsersAddModal(false)}
                closeOnOverlayClick={false}
                closeIcon={closeIcon}
              >
                <h5 className="subtitle is-5 modal-title">
                  Add new <strong>User</strong>
                </h5>

                <form onSubmit={handleSubmitUsersForm}>
                  <div className="field">
                    <label className="label">
                      <i className="fa-solid fa-face-smile mr-1"></i> Name
                    </label>
                    <div className="control">
                      <input
                        name="username"
                        className="input"
                        type="text"
                        placeholder="Enter User name"
                        value={formDataForUsers.username}
                        onChange={handleUsersFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      <i className="fa-solid fa-key mr-1"></i> Password
                    </label>
                    <div className="control">
                      <input
                        name="password"
                        className="input"
                        type="text"
                        placeholder="Create User password"
                        value={formDataForUsers.password}
                        onChange={handleUsersFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Role</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          name="role"
                          value={formDataForUsers.role}
                          onChange={handleUsersFormChange}
                        >
                          <option>Select role</option>
                          {roles.map((item) => (
                            <option
                              key={item.label}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      <i className="fa-solid fa-phone mr-1"></i> Contact
                    </label>
                    <div className="control">
                      <input
                        name="connectionDetails"
                        className="input"
                        type="text"
                        placeholder="Enter User contact details"
                        value={formDataForUsers.connectionDetails}
                        onChange={handleUsersFormChange}
                      />
                    </div>
                  </div>

                  <div className="field is-grouped is-grouped-right">
                    <div className="control">
                      <button
                        className="button is-success has-text-white pr-5 pl-5 mt-3"
                        type="submit"
                      >
                        Add new User
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>

              <Modal
                open={openUsersEditModal}
                onClose={() => setOpenUsersEditModal(false)}
                closeOnOverlayClick={false}
                closeIcon={closeIcon}
              >
                <h5 className="subtitle is-5 modal-title">
                  Edit <strong>{userSingle.username}</strong>
                </h5>

                <form onSubmit={handleSubmitUsersEditForm}>
                  <div className="field">
                    <label className="label">
                      <i className="fa-solid fa-face-smile mr-1"></i> Name
                    </label>
                    <div className="control">
                      <input
                        name="username"
                        className="input"
                        type="text"
                        placeholder="Enter User name"
                        value={userSingle.username}
                        onChange={handleUsersEditFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      <i className="fa-solid fa-phone mr-1"></i> Contact
                    </label>
                    <div className="control">
                      <input
                        name="connectionDetails"
                        className="input"
                        type="text"
                        placeholder="Enter User contact details"
                        value={userSingle.connectionDetails}
                        onChange={handleUsersEditFormChange}
                      />
                    </div>
                  </div>

                  <div className="field is-grouped is-grouped-right">
                    <div className="control">
                      <button
                        className="button is-info has-text-white pr-5 pl-5 mt-3"
                        type="submit"
                      >
                        Update User
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>

              <Modal
                open={openUsersDeleteModal}
                onClose={() => setOpenUsersDeleteModal(false)}
                closeOnOverlayClick={false}
                closeIcon={closeIcon}
              >
                <h5 className="subtitle is-5 modal-title">Are you sure?</h5>

                <div className="mt-5 mb-5">
                  You want to delete: <strong>{userName}</strong>
                </div>

                <div className="notification is-warning">
                  <i className="fa-solid fa-triangle-exclamation mr-2"></i>{" "}
                  <strong>{userName}</strong> will be deleted permanently and
                  lost access to this project!
                </div>

                <div className="field is-grouped is-grouped-right mb-0">
                  <div className="control">
                    <button
                      className="button is-danger has-text-white"
                      onClick={() => deleteUser(userID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Modal>

              <Modal
                open={openSetUserRoleModal}
                onClose={() => setOpenSetUserRoleModal(false)}
                closeOnOverlayClick={false}
                closeIcon={closeIcon}
              >
                <h5 className="subtitle is-5 modal-title">Change <strong>{userName}</strong> role?</h5>

                <form onSubmit={handleSetUserRole}>
                  <div className="field">
                    <label className="label">Role</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          name="role"
                          value={newUserRole}
                          onChange={handleUserRoleChange}
                        >
                          <option>Select role</option>
                          {roles.map((item) => (
                            <option
                              key={item.label}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field is-grouped is-grouped-right mb-0">
                    <div className="control">
                      <button
                        className="button is-info has-text-white pr-5 pl-5 mt-3"
                      >
                        Change role
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>

              <table className="table is-fullwidth is-hoverable is-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Contact</th>
                    <th>Is Password Updated?</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <UsersTable />
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
}

export default Admin;
