import { useState, useEffect } from "react";
import { authFetch } from "../providers/authProvider";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-responsive-modal";

function ShareMap({ data, id, savedName, onChange }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [mapName, setMapName] = useState("");
  const [copyText, setCopyText] = useState("Copy link");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeIcon = <i className="fa-solid fa-xmark"></i>;
  const navigate = useNavigate();

  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";

  useEffect(() => {
    if (id) {
      document.title = `Vishun: ${savedName}`;
      setMapName(savedName);
    }
  }, [id, savedName]);

  const handleMapNameFormChange = (event) => {
    const { name, value } = event.target;
    setMapName(value);
  };

  const saveMap = () => {
    const mapData = {
      name: mapName,
      objects: data,
    };

    addSharedMapData(mapData);
    onChange();
  };

  function addSharedMapData(mapData) {
    authFetch(`${API}/teams`, {
      method: "POST",
      body: JSON.stringify(mapData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const id = data.id;
        navigate(`/${id}`);
        onChange();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updateMap = () => {
    const mapData = {
      name: mapName,
      objects: data,
      id: id,
    };
    updateSharedMapData(mapData);
  };

  function updateSharedMapData(data) {
    authFetch(`${API}/teams/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  const copyLink = () => {
    setCopyText("Copied!");
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    setTimeout(() => {
      setCopyText("Copy link");
    }, 800);
  };

  const deleteMap = () => {
    deleteSharedMapData(id);
    setIsModalOpen(false);
    setIsDropdownOpen(false);
    setMapName("");
    document.title = "Vishun";
  };

  function deleteSharedMapData(id) {
    authFetch(`${API}/teams/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        navigate("/");
        onChange();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div
        className={
          isDropdownOpen ? "dropdown is-active is-right" : "dropdown is-right"
        }
      >
        <div
          className="dropdown-trigger"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <button className="button is-success is-light has-text-weight-medium pt-3 pb-3">
            <i className="fa-solid fa-map-location-dot"></i>
          </button>
        </div>
        <div
          className="dropdown-menu dropdown-menu-share-map"
          id="dropdown-menu"
          role="menu"
        >
          <div className="dropdown-content">
            <div className="share-map-label pl-4 pr-4">
              {id ? `Edit map: ${savedName}` : "Create new map"}
            </div>

            <div className="share-map">
              <div className="field share-map-form">
                <label className="label">Map name:</label>
                <div className="control">
                  <input
                    name="name"
                    className="input"
                    type="text"
                    placeholder="Enter Map name"
                    value={mapName}
                    onChange={handleMapNameFormChange}
                    required
                  />
                </div>
              </div>

              {id ? (
                <>
                  <button
                    className="button is-link has-text-white mr-4"
                    onClick={() => updateMap()}
                    disabled={mapName.length === 0 || data.length === 0}
                  >
                    <span className="icon is-small">
                      <i className="fa-solid fa-floppy-disk"></i>
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="button is-success has-text-white mr-4"
                    onClick={() => saveMap()}
                    disabled={mapName.length === 0 || data.length === 0}
                  >
                    <span className="icon is-small">
                      <i className="fa-solid fa-floppy-disk"></i>
                    </span>
                  </button>
                </>
              )}
            </div>

            <div className="share-map-bottom pl-4 pr-4">
              <button
                className="button is-success is-light"
                onClick={() => copyLink()}
                disabled={!id}
              >
                <span className="icon is-small mr-3">
                  <i className="fa-solid fa-link"></i>
                </span>{" "}
                {copyText}
              </button>

              <button
                className="button is-danger has-text-white"
                onClick={() => setIsModalOpen(true)}
                disabled={!id}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeOnOverlayClick={false}
        closeIcon={closeIcon}
      >
        <h5 className="subtitle is-5 modal-title">Are you sure?</h5>

        <div className="mt-5 mb-5">
          You want to delete: <strong>{mapName}</strong> map
        </div>

        <div className="notification is-warning">
          <i className="fa-solid fa-triangle-exclamation mr-2"></i>{" "}
          <strong>{mapName}</strong> will be deleted <strong>forever</strong>{" "}
          and for <strong>everyone</strong>!
        </div>

        <div className="field is-grouped is-grouped-right mb-0">
          <div className="control">
            <button
              className="button is-danger has-text-white"
              onClick={() => deleteMap()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ShareMap;
