import { createAuthProvider } from "react-token-auth";

// const API = "https://vps64209.hyperhost.name:3005/api/v1";

export const { useAuth, authFetch, login, logout } = createAuthProvider({
  getAccessToken: (session) => session.accessToken,
  storage: localStorage,
  onUpdateToken: (token) =>
    fetch('https://vps64209.hyperhost.name:3005/api/v1/auth/refresh', {
      method: 'POST',
      body: JSON.stringify({ token: token.refreshToken }),
      headers: {
        'Content-Type': 'application/json',
      },
  }).then(r => r.json()),
});
