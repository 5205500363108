import { useState, useEffect } from "react";
import { authFetch, logout } from "../providers/authProvider";

import TreeNav from "./TreeNav";
import Map from "./Map";

const Main = () => {
  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    loadTreeData();
  }, []);
  
  const handleTreeItemClick = (data) => {
    sessionStorage.setItem("selectedTreeItem", JSON.stringify(data));
  };

  async function loadTreeData() {
    try {
      authFetch(`${API}/sam/treeView`, {})
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 401) {
          logout();
        }
        setTreeData(data);
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <TreeNav data={treeData} onUpdate={handleTreeItemClick}/>
      <Map/>
    </>
  );
};

export default Main;