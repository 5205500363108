import { useState } from "react";
import axios from "axios";
import { login } from "../providers/authProvider";

import "bulma/css/bulma.min.css";
import "./Login.scss";

import loginImage from "../assets/login.jpg";

const Login = () => {
  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API}/auth/login`, {
        username,
        password,
      });
      const session = response.data;
      if (session) {
        setError("");
        login(session);
      }
    } catch (error) {
      setError("Invalid username or password!");
    }
  };

  return (
    <div className="login">
      <div className="block login-box">
        <img src={loginImage} alt="Login" className="login-image mb-2" />
        <h4 className="subtitle is-4 text-center">
          <i className="fa-solid fa-heart mr-2"></i>Фанати Михайла Поплавського
          <i className="fa-solid fa-heart ml-2"></i>
        </h4>

        <div className="field">
          <label className="label">
            <i className="fa-solid fa-user mr-2"></i> Username
          </label>
          <div className="control">
            <input
              className="input is-fullwidth"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">
            <i className="fa-solid fa-key mr-2"></i>Password
          </label>
          <div className="control">
            <input
              className="input is-fullwidth"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        {error && (<span className="tag is-danger mt-3 has-text-white pl-3 pr-3"><i className="fa-solid fa-triangle-exclamation mr-2"></i> {error}</span>)}

        <button
          className="button is-fullwidth is-primary has-text-white mt-5"
          onClick={handleLogin}
          disabled={!username || !password}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default Login;
