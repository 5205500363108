import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  Marker,
  Popup,
  Circle,
  Tooltip,
  GeoJSON,
} from "react-leaflet";
import L from "leaflet";
import Toggle from "react-toggle";
import { useAuth, authFetch } from "../providers/authProvider";
import toast, { Toaster } from "react-hot-toast";
import MarkerClusterGroup from "react-leaflet-cluster";
import User from "./User";
import ShareMap from "./ShareMap";
import MeasureControl from "../lib/leaflet.lib";

import defaultMap from "../assets/defaultMap.png";
import terrainMap from "../assets/terrainMap.png";
import satteliteMap from "../assets/satteliteMap.png";
import darkMap from "../assets/darkMap.png";
import matrixMap from "../assets/matrixMap.png";

import "bulma/css/bulma.min.css";
import "leaflet/dist/leaflet.css";
import "react-toggle/style.css";
import "./Map.scss";

import allData from "../all.geo.json";
import out from "../out.json";
import Empty from "../assets/icons/empty.png";
import Default from "../assets/icons/default.png";
import DotIcon from "../assets/icons/dot.png";
import PinIcon from "../assets/icons/pin.png";

import regiony from "../geojson/regiony.json";
import rayony from "../geojson/rayony.json";
import hromady from "../geojson/hromady.json";

// NATO icons
import ADML from "../assets/nato/ADML.png";
import RLS from "../assets/nato/RLS.png";
import SAM_Buk from "../assets/nato/SAM_Buk.png";
import SAM_Osa from "../assets/nato/SAM_Osa.png";
import SAM_S300 from "../assets/nato/SAM_S300.png";
import SAM_Tor from "../assets/nato/SAM_Tor.png";
import Tank from "../assets/nato/Tank.png";

// ICONS
import Icon001 from "../assets/icons/001.png"; // 9С18 «Купол»
import Icon002 from "../assets/icons/002.png"; // Бук-М3
import Icon003 from "../assets/icons/003.png"; // ЗРК Оса
import Icon004 from "../assets/icons/004.png"; // РЛС Гармон 1Л122-1Е
import Icon005 from "../assets/icons/005.png"; // РЛС Каста-2Е1
import Icon006 from "../assets/icons/006.png"; // РЛС КБС Зоопарк
import Icon007 from "../assets/icons/007.png"; // РЛС П-18
import Icon008 from "../assets/icons/008.png"; // С-200
import Icon009 from "../assets/icons/009.png"; // С-300В
import Icon010 from "../assets/icons/010.png"; // Тор м1
import Icon011 from "../assets/icons/011.png"; // Тор м2
import Icon012 from "../assets/icons/012.png"; // Тунгуска-М1
import Icon013 from "../assets/icons/013.png"; // C-500
import Icon014 from "../assets/icons/014.png"; // Панцир
import Icon015 from "../assets/icons/015.png"; // Бук
import Icon016 from "../assets/icons/016.png"; // Тор
import Icon017 from "../assets/icons/017.png"; // Стріла-10
import Icon018 from "../assets/icons/018.png"; // ЗСУ-23-4
import Icon019 from "../assets/icons/019.png"; // 64Н6Е2
import Icon020 from "../assets/icons/020.png"; // 30Н6Е2
import Icon021 from "../assets/icons/021.png"; // 96ЛЕ6
import Icon022 from "../assets/icons/022.png"; // 36Д6
import Icon023 from "../assets/icons/023.png"; // 1Т12М2
import Icon024 from "../assets/icons/024.png"; // 9S32
import Icon025 from "../assets/icons/025.png"; // C-400
import Icon026 from "../assets/icons/026.png"; // 5Н62В
import Icon027 from "../assets/icons/027.png"; // 50Н6А
import Icon028 from "../assets/icons/028.png"; // 91Н6
import Icon029 from "../assets/icons/029.png"; // С-75 Дубна и Волга
import Icon030 from "../assets/icons/030.png"; // С-300ПМ
import Icon031 from "../assets/icons/031.png"; // с-350
import Icon032 from "../assets/icons/032.png"; // 5Н62
import Icon033 from "../assets/icons/033.png"; // Панцирь С-2
import Icon34 from "../assets/icons/034.png"; // 1Л122-Е2 Гармонь
import Icon35 from "../assets/icons/035.png"; // 1Л277 Соболятник
import Icon36 from "../assets/icons/036.png"; // 1РЛ123Е Истра
import Icon37 from "../assets/icons/037.png"; // 9С18М Купол-М
import Icon38 from "../assets/icons/038.png"; // 29Б6 Контейнер
import Icon39 from "../assets/icons/039.png"; // 48Я6-К1 Подлет-К1
import Icon40 from "../assets/icons/040.png"; // 77Я6 Воронеж-М
import Icon41 from "../assets/icons/041.png"; // П-18-2 Прима
import Icon42 from "../assets/icons/042.png"; // РСП-27С
import Icon43 from "../assets/icons/043.png"; // 1Л122-1Е Гармонь
import Icon44 from "../assets/icons/044.png"; // 5П21 Подсолнух
import Icon45 from "../assets/icons/045.png"; // 55Ж6М Небо-М

function Map() {
  const api_key = "acc5e8d6-0716-40fc-960a-fe54687915a0";
  const api_key_jawg =
    "rHiLT4dDWLbVfLakztmE42uHFebPSOWMzpGTA0OiUDgyYhDzcg3rAn4qm7AWz1OE";

  const defaultMapTypeURL =
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const terrainMapTypeURL =
    "https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png?api_key=" +
    api_key;
  const satelliteMapTypeURL =
    "https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}";
  const jawgDarkMapTypeURL = `https://tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=${api_key_jawg}`;
  const jawgMatrixMapTypeURL = `https://tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token=${api_key_jawg}`;

  const defaultCenter = [49, 31];
  const defaultZoom = 6;

  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";
  const [logged, session] = useAuth();

  const { id } = useParams();
  const [mapName, setMapName] = useState("");

  const [markers, setMarkers] = useState([]);
  const [dots, setDots] = useState([]);
  const [mapTypeURL, setMapTypeURL] = useState(defaultMapTypeURL);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [markerId, setMarkerId] = useState();
  const [coords, setCoords] = useState("");
  const [isIconNato, setIconType] = useState(false);
  const [isHideIcons, setIsHideIcons] = useState(false);
  const [isNamesBg, setIsNamesBg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAlt, setIsLoadingAlt] = useState(false);
  const [isOblast, setIsOblast] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownSettingsOpen, setIsDropdownSettingsOpen] = useState(false);
  const [isMapInfo, setIsMapInfo] = useState(true);
  const [isMapInfoMarkers, setIsMapInfoMarkers] = useState(true);
  const [markerAdditionalInfo, setMarkerAdditionalInfo] = useState([]);
  const [markerAdditionalInfoMap, setMarkerAdditionalInfoMap] = useState([]);

  var dataMarkers = null;

  const mapRef = useRef();

  const countryBorders = {
    fillOpacity: 0,
    color: "#5D4037",
    weight: 1.5,
  };

  const fillRedOptions = {
    fillColor: "#EF6C00",
    color: "#EF6C00",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.2,
  };
  const fillYellowOptions = {
    fillColor: "#FFEA00",
    color: "#FFEA00",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.2,
  };
  const fillGreenOptions = {
    fillColor: "#008000",
    color: "#008000",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.2,
  };
  const fillGrayOptions = {
    fillColor: "#4527A0",
    color: "#4527A0",
    weight: 1,
    opacity: 0.35,
    fillOpacity: 0.2,
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === "KeyH") {
        setIsCollapsed(!isCollapsed);
      }
    };

    const storedData = JSON.parse(sessionStorage.getItem("markers"));
    if (storedData && storedData.length > 0) {
      setMarkers(storedData);
    }

    if (id) {
      getMapByID(id);
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isCollapsed, session, id]);

  const handleWrongCoordsToaster = (message) => {
    toast.error(message, {
      duration: 2000,
      position: "top-left",
      style: {
        width: "250px",
      },
    });
  };

  const getMapByID = async (id) => {
    authFetch(`${API}/teams/${id}`, {})
      .then((response) => response.json())
      .then((data) => {
        const mapData = data.objects;
        const mapName = data.name;
        setMarkers(mapData);
        setMapName(mapName);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);
    try {
      authFetch(`${API}/data/import`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          parseData(data);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleFileDownload = async (data) => {
    try {
      authFetch(`${API}/data/export`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          const dataTime = new Date().toLocaleString().replace(/:/g, "-");
          downloadLink.setAttribute("download", `export-${dataTime}.csv`);
          downloadLink.click();
        });
    } catch (error) {
      console.error(error);
    }
  };

  function loadMapAdditionalInfo(input) {
    authFetch(
      `${API}/map/objects-in-circle?lon=${input.lng}&lat=${input.lat}&radius=${input.radius}`,
      {}
    )
      .then((response) => response.json())
      .then((data) => {
        const markersData = {
          id: input.id,
          data: data.features,
        };
        if (dataMarkers) {
          const newData = [...dataMarkers, markersData];
          setMarkerAdditionalInfo(newData);
          generateMapData(newData);
          dataMarkers = null;
        } else {
          const newData = [...markerAdditionalInfo, markersData];
          setMarkerAdditionalInfo(newData);
          generateMapData(newData);
          dataMarkers = null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const generateMapData = (data) => {
    let generatedData = data.map((item) => item.data);
    let mergedData = generatedData.flat();
    setMarkerAdditionalInfoMap(mergedData);
  };

  const parseData = (data) => {
    const parsedData = data.map((marker, index) => {
      return {
        id: index + 1,
        name: marker.name,
        minRange: marker.minRange,
        maxRange: marker.maxRange,
        position: marker.position,
        icon: marker.icon || null,
        iconNato: marker.iconNato || null,
        type: marker.type,
      };
    });
    setMarkers(parsedData);
  };

  const getIcon = (mapIcon, mapIconNato) => {
    if (isHideIcons) {
      return L.icon({
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        iconUrl: Empty,
        shadowUrl: null,
      });
    } else if (!mapIcon && mapIconNato) {
      return L.icon({
        iconSize: [36, 36],
      iconAnchor: [18, 18],
      popupAnchor: [0, -12],
      iconUrl: isIconNato
        ? getIconByNatoName(mapIconNato)
        : Default,
      shadowUrl: null,
      });
    } else if (mapIcon && !mapIconNato) {
      return L.icon({
        iconSize: [36, 36],
        iconAnchor: [18, 18],
        popupAnchor: [0, -12],
        iconUrl: isIconNato
        ? Default
        : getIconByIconName(mapIcon),
        shadowUrl: null,
      });
    } else if (mapIcon && mapIconNato) {
      return L.icon({
        iconSize: [36, 36],
        iconAnchor: [18, 18],
        popupAnchor: [0, -12],
        iconUrl: isIconNato
        ? getIconByNatoName(mapIconNato)
        : getIconByIconName(mapIcon),
        shadowUrl: null,
      });
    }

    return L.icon({
      iconSize: [36, 36],
      iconAnchor: [18, 18],
      popupAnchor: [0, -12],
      iconUrl: Default,
      shadowUrl: null,
    });
  };

  const getDotIcon = () => {
    return L.icon({
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -11],
      iconUrl: DotIcon,
      shadowUrl: null,
    });
  };

  const getPinIcon = () => {
    return L.icon({
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -11],
      iconUrl: PinIcon,
      shadowUrl: null,
    });
  };

  const getIconByNatoName = (natoName) => {
    switch (natoName) {
      case "ADML":
        return ADML;
      case "RLS":
        return RLS;
      case "SAM Buk":
        return SAM_Buk;
      case "SAM Osa":
        return SAM_Osa;
      case "SAM S300":
        return SAM_S300;
      case "SAM Tor":
        return SAM_Tor;
      case "Tank":
        return Tank;
      default:
        return Default;
    }
  };

  const getIconByIconName = (iconName) => {
    switch (iconName) {
      case "9С18 «Купол»":
        return Icon001;
      case "Бук-М3":
        return Icon002;
      case "ЗРК Оса":
        return Icon003;
      case "РЛС Гармон 1Л122-1Е":
        return Icon004;
      case "РЛС Каста-2Е1":
        return Icon005;
      case "РЛС КБС Зоопарк":
        return Icon006;
      case "РЛС П-18":
        return Icon007;
      case "С-200":
        return Icon008;
      case "С-300В":
        return Icon009;
      case "Тор м1":
        return Icon010;
      case "Тор м2":
        return Icon011;
      case "Тунгуска-М1":
        return Icon012;
      case "C-500":
        return Icon013;
      case "Панцир":
        return Icon014;
      case "Бук":
        return Icon015;
      case "Тор":
        return Icon016;
      case "Стріла-10":
        return Icon017;
      case "ЗСУ-23-4":
        return Icon018;
      case "64Н6Е2":
        return Icon019;
      case "30Н6Е2":
        return Icon020;
      case "96ЛЕ6":
        return Icon021;
      case "36Д6":
        return Icon022;
      case "1Т12М2":
        return Icon023;
      case "9S32":
        return Icon024;
      case "C-400":
        return Icon025;
      case "5Н62В":
        return Icon026;
      case "50Н6А":
        return Icon027;
      case "91Н6":
        return Icon028;
      case "С-75 Дубна и Волга":
        return Icon029;
      case "С-300ПМ":
        return Icon030;
      case "с-350":
        return Icon031;
      case "5Н62":
        return Icon032;
      case "Панцирь С-2":
        return Icon033;
      case "1Л122-Е2 Гармонь":
        return Icon34;
      case "1Л277 Соболятник":
        return Icon35;
      case "1РЛ123Е Истра":
        return Icon36;
      case "9С18М Купол-М":
        return Icon37;
      case "29Б6 Контейнер":
        return Icon38;
      case "48Я6-К1 Подлет-К1":
        return Icon39;
      case "77Я6 Воронеж-М":
        return Icon40;
      case "П-18-2 Прима":
        return Icon41;
      case "РСП-27С":
        return Icon42;
      case "1Л122-1Е Гармонь":
        return Icon43;
      case "5П21 Подсолнух":
        return Icon44;
      case "55Ж6М Небо-М":
        return Icon45;
      default:
        return null;
    }
  };

  const getID = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const MapEvents = ({ markers, setMarkers, ...rest }) => {
    const map = useMapEvents({
      click: (e) => {
        const selectedItem = JSON.parse(
          sessionStorage.getItem("selectedTreeItem")
        );
        if (selectedItem) {
          if (!isDeleted) {
            const { lat, lng } = e.latlng;
            const newMarkers = JSON.parse(JSON.stringify(markers));
            const createdMarker = {
              id: getID(),
              name: selectedItem?.name,
              minRange: selectedItem?.minRange,
              maxRange: selectedItem?.maxRange,
              position: [lat, lng],
              icon: selectedItem?.icon,
              iconNato: selectedItem?.iconNato,
              type: selectedItem?.type,
              samName: selectedItem?.samName,
              mapName: selectedItem?.mapName,
            };
            newMarkers.push(createdMarker);
            setMarkers(newMarkers);
            setDots([]);
            loadMapAdditionalInfo({
              id: createdMarker.id,
              lat: lat,
              lng: lng,
              radius: selectedItem?.maxRange,
            });
          }
        }
      },
    });
    return null;
  };

  const updateMarkerPosition = (id, latLng) => {
    const updatedMarkers = markers.map((marker) => {
      if (marker.id === id) {
        return { ...marker, position: [latLng.lat, latLng.lng] };
      }
      return marker;
    });
    setMarkers(updatedMarkers);
  };

  const generateCircleColor = (range, type) => {
    if (isHideIcons) {
      if (type === "radar") {
        fillGrayOptions.fillOpacity = 0.1;
        return fillGrayOptions;
      } else {
        if (range <= 10000) {
          fillGreenOptions.fillOpacity = 0.05;
          return fillGreenOptions;
        } else if (range <= 40000) {
          fillYellowOptions.fillOpacity = 0.05;
          return fillYellowOptions;
        } else {
          fillRedOptions.fillOpacity = 0.05;
          return fillRedOptions;
        }
      }
    } else {
      if (type === "radar") {
        return fillGrayOptions;
      } else {
        if (range <= 10000) {
          return fillGreenOptions;
        } else if (range <= 40000) {
          return fillYellowOptions;
        } else {
          return fillRedOptions;
        }
      }
    }
  };

  const handleAddMarker = () => {
    const inputCoords = document.getElementById("cords").value;
    const splitedCoords = inputCoords.split(",");
    const selectedItem = JSON.parse(sessionStorage.getItem("selectedTreeItem"));

    if (isNaN(splitedCoords[0]) || isNaN(splitedCoords[1])) {
      handleWrongCoordsToaster("Wrong coorinates format!");
      return;
    }

    if (splitedCoords[0] === "" || splitedCoords[1] === "") {
      handleWrongCoordsToaster("Wrong coorinates format!");
      return;
    }

    if (!selectedItem) return;

    const newMarkers = JSON.parse(JSON.stringify(markers));
    const createdMarker = {
      id: getID(),
      name: selectedItem?.name,
      category: selectedItem?.optionGroup,
      minRange: selectedItem?.minRange,
      maxRange: selectedItem?.maxRange,
      optionGroup: selectedItem?.optionGroup,
      position: splitedCoords,
      icon: selectedItem?.icon,
      iconNato: selectedItem?.iconNato,
      type: selectedItem?.type,
      samName: selectedItem?.samName,
      mapName: selectedItem?.mapName,
    };

    sessionStorage.setItem("markers", JSON.stringify(newMarkers));

    newMarkers.push(createdMarker);
    setMarkers(newMarkers);
    setDots([]);
    mapRef.current.setView(splitedCoords, defaultZoom, {
      animate: true,
    });
    setCoords("");

    loadMapAdditionalInfo({
      lat: splitedCoords[0],
      lng: splitedCoords[1],
      radius: selectedItem?.maxRange,
    });
  };

  const handleUpdateMarker = (id) => {
    const inputCoords = document.getElementById("cords").value;
    const splitedCoords = inputCoords.split(",");

    const updatedMarkers = markers.map((marker) => {
      if (marker.id === id) {
        return {
          ...marker,
          position: splitedCoords,
        };
      }
      return marker;
    });
    setMarkers(updatedMarkers);
    mapRef.current.setView(splitedCoords, defaultZoom, {
      animate: true,
    });
    setCoords("");
    setDots([]);
    setIsEditing(false);
  };

  const handleCheckbox = (e) => {
    setIconType(e.target.checked);
  };

  const handleCheckboxTwo = (e) => {
    setIsHideIcons(e.target.checked);
  };

  const handleCheckboxThree = (e) => {
    setIsNamesBg(e.target.checked);
  };

  const handleCheckboxFour = (e) => {
    setIsMapInfo(e.target.checked);
  };

  const handleCheckboxFive = (e) => {
    setIsMapInfoMarkers(e.target.checked);
  };

  const handleCheckboxSix = (e) => {
    setIsOblast(e.target.checked);
  };

  const handleGoCoords = () => {
    const inputCoords = document.getElementById("cords").value;
    const splitedCoords = inputCoords.split(",");

    if (isNaN(splitedCoords[0]) || isNaN(splitedCoords[1])) {
      handleWrongCoordsToaster("Wrong coorinates format!");
      return;
    }

    if (splitedCoords[0] === "" || splitedCoords[1] === "") {
      handleWrongCoordsToaster("Wrong coorinates format!");
      return;
    }

    const newDot = [
      {
        id: 1,
        position: splitedCoords,
      },
    ];
    setDots(newDot);
    mapRef.current.setView(splitedCoords, 11, {
      animate: true,
    });
  };

  const handleDelete = (id) => {
    setIsDeleted(true);
    const newMarkers = markers.filter((marker) => marker.id !== id);
    setMarkers(newMarkers);
    setIsEditing(false);
    setCoords("");
    setTimeout(() => {
      setIsDeleted(false);
    });
    const markerData = markerAdditionalInfo.filter(
      (marker) => marker.id !== id
    );
    generateMapData(markerData);
    setMarkerAdditionalInfo(markerData);
  };

  const handleUpdateCoordinates = (id, position) => {
    setMarkerId(id);
    setIsEditing(true);
    setIsDeleted(true);
    const coords = position[0] + ", " + position[1];
    setCoords(coords);
    setTimeout(() => {
      setIsDeleted(false);
    });
  };

  const handleCoordsChange = (e) => {
    const coords = e.target.value;
    setCoords(coords);
  };

  const handleParseCoordsAndAddMarker = () => {
    if (typeof coords === "string") {
      if (coords.includes(",")) {
        const coordsArray = coords.split(",");
        if (coordsArray.length === 2) {
          const latitude = coordsArray[0];
          const longitude = coordsArray[1];
          if (!isNaN(latitude) && !isNaN(longitude)) {
            const parsedCoords = latitude + ", " + longitude;
            setCoords(parsedCoords);
          } else {
            parseCoordinates(coords);
          }
        } else {
          parseCoordinates(coords);
        }
      } else {
        if (isNaN(coords[0]) && isNaN(coords[1])) {
          parseCoordinates(coords);
        } else {
          const coordsArray = coords.split(" ");
          if (coordsArray.length === 2) {
            const latitude = coordsArray[0];
            const longitude = coordsArray[1];

            if (!isNaN(latitude) && !isNaN(longitude)) {
              const parsedCoords = latitude + ", " + longitude;
              setCoords(parsedCoords);
            } else {
              parseCoordinates(coords);
            }
          } else {
            parseCoordinates(coords);
          }
        }
      }
    } else {
      setCoords(coords);
    }
  };

  function parseCoordinates(coords) {
    if (coords.includes(",")) {
      const coordsArray = coords.split(",");
      if (coordsArray.length === 2) {
        const latitude = coordsArray[0];
        const longitude = coordsArray[1];
        convertCoordinates([latitude, longitude]);
      } else if (coordsArray.length === 4) {
        const latitude = coordsArray[0] + coordsArray[1];
        const longitude = coordsArray[2] + coordsArray[3];
        convertCoordinates([latitude, longitude]);
      } else if (coordsArray.length === 6) {
        const latitude = coordsArray[0] + coordsArray[1] + coordsArray[2];
        const longitude = coordsArray[3] + coordsArray[4] + coordsArray[5];
        convertCoordinates([latitude, longitude]);
      }
    } else {
      const coordsArray = coords.split(" ");
      if (coordsArray.length === 2) {
        const latitude = coordsArray[0];
        const longitude = coordsArray[1];
        convertCoordinates([latitude, longitude]);
      } else if (coordsArray.length === 4) {
        const latitude = coordsArray[0] + coordsArray[1];
        const longitude = coordsArray[2] + coordsArray[3];
        convertCoordinates([latitude, longitude]);
      } else if (coordsArray.length === 6) {
        const latitude = coordsArray[0] + coordsArray[1] + coordsArray[2];
        const longitude = coordsArray[3] + coordsArray[4] + coordsArray[5];
        convertCoordinates([latitude, longitude]);
      }
    }
  }

  function convertCoordinates(coords) {
    const convertedCoords = coords.map((coord) => {
      if (!coord.includes("°")) {
        return;
      }
      const degrees = coord.split("°")[0];
      const minutes = coord.split("°")[1].split("'")[0];
      const seconds = coord.split("'")[1];
      const converterMinutes = Number(minutes) / 60;
      const converterSeconds = Number(seconds) / 3600;
      const coordinate = Number(degrees) + converterMinutes + converterSeconds;
      return coordinate;
    });
    if (convertedCoords.includes(undefined)) {
      setCoords(coords);
    } else if (isNaN(convertedCoords[0]) || isNaN(convertedCoords[1])) {
      setCoords(coords);
    } else {
      setCoords(convertedCoords.join(", "));
    }
  }

  const handleFileSelcted = () => {
    const fileInput = document.querySelector('input[type="file"]');
    fileInput.click();
    fileInput.addEventListener("change", (e) => {
      const file = e.target.files[0];
      handleFileUpload(file);
    });
  };

  const handleSharedMapEvents = () => {
    setIsLoadingAlt(true);
    setTimeout(() => {
      setIsLoadingAlt(false);
    }, 1000);
  };

  const handleFileGenerate = () => {
    handleFileDownload(markers);
  };

  const DraggableMarker = (marker) => {
    const markerRef = useRef(null);
    const circleRangeRef = useRef(null);

    const eventHandlers = {
      drag() {
        const marker = markerRef.current;
        if (marker != null) {
          const latLng = marker.getLatLng();
          circleRangeRef.current.setLatLng(latLng);
        }
      },
    };

    return (
      <>
        <Marker
          key={marker.id}
          position={marker.position}
          icon={getIcon(marker.icon, marker.iconNato)}
          draggable={true}
          eventHandlers={{
            dragend: (e) => {
              const newLatLng = e.target.getLatLng();
              updateMarkerPosition(marker.id, newLatLng);
              const markerData = markerAdditionalInfo.filter(
                (markerMap) => markerMap.id !== marker.id
              );
              dataMarkers = markerData;
              // setMarkerAdditionalInfoMapTemp(markerData);
              // generateMapData(markerData);
              // setMarkerAdditionalInfo(markerData);
              setTimeout(() => {
                loadMapAdditionalInfo({
                  id: marker.id,
                  lat: newLatLng.lat,
                  lng: newLatLng.lng,
                  radius: marker.maxRange,
                });
              }, 100);
            },
            drag: (e) => {
              const marker = markerRef.current;
              if (marker != null) {
                const latLng = marker.getLatLng();
                circleRangeRef.current.setLatLng(latLng);
              }
            },
          }}
          ref={markerRef}
        >
          {isNamesBg ? (
            <Tooltip permanent direction="top" offset={[0, -8]} opacity={1}>
              {marker.type === "radar" ? (
                <>{marker.name}</>
              ) : (
                <>{marker.mapName}</>
              )}
            </Tooltip>
          ) : (
            <Tooltip
              className="marker-tooltip"
              permanent
              direction="top"
              offset={[0, -8]}
              opacity={1}
            >
              {isHideIcons ? (
                ""
              ) : (
                <span className="marker-label">
                  {marker.type === "radar" ? (
                    <>{marker.name}</>
                  ) : (
                    <>{marker.mapName}</>
                  )}
                </span>
              )}
            </Tooltip>
          )}
          <Popup>
            <div className="popup">
              <img src={getIconByIconName(marker.icon)} alt="Icon" />
              <div className="popup-title">
                <span className="popup-strong">Type:</span> {marker.name}
              </div>
              <div className="popup-title">
                <span className="popup-strong">Max Range:</span>{" "}
                {marker.maxRange / 1000} km
              </div>
              <div className="popup-title">
                <span className="popup-strong">Lat:</span> {marker.position[0]}
              </div>
              <div className="popup-title">
                <span className="popup-strong">Lng:</span> {marker.position[1]}
              </div>

              <div className="columns mt-3 mb-1">
                <div className="column">
                  <div className="buttons">
                    <button
                      className="button is-success popup-btn is-fullwidth"
                      onClick={() =>
                        handleUpdateCoordinates(marker.id, marker.position)
                      }
                    >
                      <span className="icon mr-3">
                        <i className="fa-regular fa-pen-to-square"></i>
                      </span>
                      Update coords
                    </button>
                  </div>
                </div>
                <div className="column">
                  <div className="buttons">
                    <button
                      className="button is-danger popup-btn is-fullwidth"
                      onClick={() => handleDelete(marker.id)}
                    >
                      <span className="icon mr-3">
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          <Circle
            center={marker.position}
            radius={marker.maxRange}
            ref={circleRangeRef}
            pathOptions={generateCircleColor(marker.maxRange, marker.type)}
          />
        </Marker>
      </>
    );
  };

  return (
    <div className="Map">
      {isLoading && (
        <div className="overlay">
          <span className="loader-icon"></span>
        </div>
      )}

      {isLoadingAlt && (
        <div className="overlay">
          <span className="loader1"></span>
        </div>
      )}
      <div className="controls">
        <div className={!isCollapsed ? "wrapper wrapper-collapsed" : "wrapper"}>
          <div className="controls-left">
            <div className="field mb-0">
              <p className="control has-icons-left">
                <input
                  className="input is-fullwidth"
                  type="text"
                  placeholder="Latitude, Longtitude"
                  value={coords}
                  id="cords"
                  onChange={handleCoordsChange}
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-globe"></i>
                </span>
              </p>
            </div>

            {coords && (
              <div className="buttons is-centered mb-0">
                <button
                  className="button is-info  is-light has-text-weight-medium nav-btn"
                  onClick={() => {
                    handleParseCoordsAndAddMarker();
                    setTimeout(() => {
                      handleGoCoords();
                    }, 300);
                  }}
                >
                  GO
                </button>
                {!isEditing && (
                  <button
                    className="button is-link is-light has-text-weight-medium nav-btn"
                    onClick={() => {
                      handleParseCoordsAndAddMarker();
                      setTimeout(() => {
                        handleAddMarker();
                      }, 300);
                    }}
                  >
                    Add
                  </button>
                )}
                {isEditing && (
                  <button
                    className="button is-link is-light has-text-weight-medium nav-btn"
                    onClick={() => handleUpdateMarker(markerId)}
                  >
                    Update
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="controls-right">
            <div className="file is-success mb-0">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  onClick={() => handleFileSelcted()}
                />
                <span className="file-cta has-text-white">
                  <span className="file-icon">
                    <i className="fa-solid fa-file-arrow-up"></i>
                  </span>
                  <span className="file-label">Upload</span>
                </span>
              </label>
            </div>

            <button
              className="button is-info is-light"
              onClick={() => handleFileGenerate()}
              disabled={markers.length === 0}
            >
              <span className="icon is-small">
                <i className="fa-solid fa-floppy-disk"></i>
              </span>
            </button>

            <div
              className={
                isDropdownOpen
                  ? "dropdown dropdown-map is-active"
                  : "dropdown dropdown-map"
              }
            >
              <div
                className="dropdown-trigger dropdown-trigger-map"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <button
                  className="button is-fullwidth dropdown-button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>
                    <i className="fa-solid fa-layer-group mr-2"></i>Select map
                    type
                  </span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div
                className="dropdown-menu dropdown-menu-map"
                id="dropdown-menu"
                role="menu"
              >
                <div className="dropdown-content">
                  <a
                    href="#"
                    onClick={() => {
                      setMapTypeURL(defaultMapTypeURL);
                      setIsDropdownOpen(false);
                    }}
                    className={
                      mapTypeURL === defaultMapTypeURL
                        ? "dropdown-item dropdown-item-map is-active"
                        : "dropdown-item dropdown-item-map"
                    }
                  >
                    <img
                      src={defaultMap}
                      alt="Default Map"
                      className="dropdown-image"
                    />
                    Default
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      setMapTypeURL(terrainMapTypeURL);
                      setIsDropdownOpen(false);
                    }}
                    className={
                      mapTypeURL === terrainMapTypeURL
                        ? "dropdown-item dropdown-item-map is-active"
                        : "dropdown-item dropdown-item-map"
                    }
                  >
                    <img
                      src={terrainMap}
                      alt="Terrain Map"
                      className="dropdown-image"
                    />
                    Terrain
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      setMapTypeURL(satelliteMapTypeURL);
                      setIsDropdownOpen(false);
                    }}
                    className={
                      mapTypeURL === satelliteMapTypeURL
                        ? "dropdown-item dropdown-item-map is-active"
                        : "dropdown-item dropdown-item-map"
                    }
                  >
                    <img
                      src={satteliteMap}
                      alt="Sattelite Map"
                      className="dropdown-image"
                    />
                    Sattelite
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      setMapTypeURL(jawgDarkMapTypeURL);
                      setIsDropdownOpen(false);
                    }}
                    className={
                      mapTypeURL === jawgDarkMapTypeURL
                        ? "dropdown-item dropdown-item-map is-active"
                        : "dropdown-item dropdown-item-map"
                    }
                  >
                    <img
                      src={darkMap}
                      alt="Sattelite Map"
                      className="dropdown-image"
                    />
                    Dark
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      setMapTypeURL(jawgMatrixMapTypeURL);
                      setIsDropdownOpen(false);
                    }}
                    className={
                      mapTypeURL === jawgMatrixMapTypeURL
                        ? "dropdown-item dropdown-item-map is-active"
                        : "dropdown-item dropdown-item-map"
                    }
                  >
                    <img
                      src={matrixMap}
                      alt="Sattelite Map"
                      className="dropdown-image"
                    />
                    Matrix
                  </a>
                </div>
              </div>
            </div>

            <ShareMap data={markers} id={id} savedName={mapName} onChange={handleSharedMapEvents} />

            <div
              className={
                isDropdownSettingsOpen
                  ? "dropdown is-active is-right"
                  : "dropdown is-right"
              }
            >
              <div
                className="dropdown-trigger"
                onClick={() =>
                  setIsDropdownSettingsOpen(!isDropdownSettingsOpen)
                }
              >
                <button className="button is-info is-light has-text-weight-medium pt-3 pb-3">
                  <i className="fa-solid fa-gear"></i>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isIconNato}
                      icons={false}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="ml-2 toggler-text highlite-text">
                      <i className="fa-solid fa-location-crosshairs mr-1 has-text-grey"></i>{" "}
                      NATO icons
                    </span>
                  </label>

                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isHideIcons}
                      icons={false}
                      onChange={(e) => handleCheckboxTwo(e)}
                    />
                    <span className="ml-2 toggler-text">
                      <i className="fa-solid fa-eye-slash mr-1 has-text-grey"></i>{" "}
                      Hide icons
                    </span>
                  </label>

                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isNamesBg}
                      icons={false}
                      onChange={(e) => handleCheckboxThree(e)}
                    />
                    <span className="ml-2 toggler-text">
                      <i className="fa-solid fa-message mr-1 has-text-grey"></i>{" "}
                      Names bg
                    </span>
                  </label>
                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isMapInfo}
                      icons={false}
                      onChange={(e) => handleCheckboxFour(e)}
                    />
                    <span className="ml-2 toggler-text">
                      <i className="fa-solid fa-border-none mr-1 has-text-grey"></i>{" "}
                      Front line
                    </span>
                  </label>
                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isMapInfoMarkers}
                      icons={false}
                      onChange={(e) => handleCheckboxFive(e)}
                    />
                    <span className="ml-2 toggler-text">
                      <i className="fa-solid fa-map-location-dot mr-1 has-text-grey"></i>{" "}
                      Targets
                    </span>
                  </label>
                  <label className="toggler">
                    <Toggle
                      defaultChecked={!!isOblast}
                      icons={false}
                      onChange={(e) => handleCheckboxSix(e)}
                    />
                    <span className="ml-2 toggler-text">
                      <i className="fa-solid fa-map mr-1 has-text-grey"></i>{" "}
                      UA Regions
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <User />
          </div>
        </div>
        <div
          className={
            !isCollapsed
              ? "controls-toggle controls-toggle-collapsed"
              : "controls-toggle"
          }
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed && <i className="fa-solid fa-chevron-up"></i>}
          {!isCollapsed && <i className="fa-solid fa-chevron-down"></i>}
        </div>
      </div>

      <MapContainer
        ref={mapRef}
        center={defaultCenter}
        zoom={defaultZoom}
        scrollWheelZoom={true}
        style={{ height: "100vh", width: "100%" }}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapTypeURL}
        />
        <MeasureControl />
        <GeoJSON style={countryBorders} data={allData.features} />
        {isMapInfo && (
          <GeoJSON
            data={out.features}
            style={() => ({
              color: "#C62828",
            })}
            pointToLayer={(feature, latlng) => {
              return L.marker(latlng, { icon: getPinIcon() });
            }}
          />
        )}
        {markers.map((marker) => (
          <DraggableMarker key={marker.id} {...marker} />
        ))}
        {dots.map((dot) => (
          <Marker key={dot.id} position={dot.position} icon={getDotIcon()} />
        ))}

        {isMapInfoMarkers &&
          markerAdditionalInfoMap &&
          markerAdditionalInfoMap.length > 0 && (
            <MarkerClusterGroup chunkedLoading>
              <GeoJSON
                key={markerAdditionalInfoMap.length}
                data={markerAdditionalInfoMap}
                style={() => ({
                  color: "#4527A0",
                })}
                pointToLayer={(feature, latlng) => {
                  return L.marker(latlng, { icon: getPinIcon() });
                }}
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindPopup(feature.properties.name);
                    layer.bindTooltip(feature.properties.name);
                  }
                }}
              />
            </MarkerClusterGroup>
          )}

        {isOblast && (
          <>
            <GeoJSON
              data={regiony.features}
              style={() => ({
                weight: 1,
                color: "#757575",
                fillColor: "#fff",
                fillOpacity: 0,
              })}
              onEachFeature={(feature, layer) => {
                if (feature.properties && feature.properties.region) {
                  layer.bindPopup(
                    decodeURIComponent(feature.properties.region)
                  );
                  layer.bindTooltip(
                    decodeURIComponent(feature.properties.region)
                  );
                }
              }}
            />
          </>
        )}

        {/* <GeoJSON
            data={rayony.features}
            style={() => ({
              weight: 1,
              color: "#757575",
              fillColor: '#fff',
              fillOpacity: 0.5,
            })}
            onEachFeature={(feature, layer) => {
              if (feature.properties && feature.properties.rayon) {
                layer.bindPopup(decodeURIComponent(feature.properties.rayon));
                layer.bindTooltip(decodeURIComponent(feature.properties.rayon));
              }
            }}
          /> */}
        <MapEvents markers={markers} setMarkers={setMarkers} />
        <Toaster position="top-left" />
      </MapContainer>
    </div>
  );
}

export default Map;
