import React, { memo, useState, useEffect } from "react";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Logo from "../assets/eye.gif";

const TreeNav = memo(function TreeNav({ data, onUpdate }) {
  const [isSideCollapsed, setIsSideCollapsed] = useState(true);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === "KeyH") {
        setIsSideCollapsed(!isSideCollapsed);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isSideCollapsed]);

  function sortData(data) {
    return data.sort((a, b) => {
      if (a.name === "") {
        return 1;
      }
      if (b.name === "") {
        return -1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const handleItemClick = (child, parent) => {
    const icon = child.icon || parent.icon;
    const iconNato = child.iconNato || parent.iconNato;
    child.icon = icon;
    child.iconNato = iconNato;
    child.samName = parent.importName;
    // ${sam.importName} RLS ${rls.importName}
    child.mapName = parent.name;
    onUpdate(child);
  };

  const generateID = () => {
    return "id_" + Math.floor(Math.random() * 1000000);
  };

  return (
    <div className="controls-side">
      <div
        className={
          !isSideCollapsed
            ? "wrapper-side wrapper-side-collapsed"
            : "wrapper-side"
        }
      >
        <div className="main-title mb-3 pl-2">
          <img src={Logo} className="logo mr-3" /> Віщун
        </div>
        <div className="tree-data">
          {data.length === 0 && (
            <div className="loading-tree">
              <span className="loader1"></span>
            </div>
            )
          }
          {data && data.length > 0 && (
            <SimpleTreeView>
              {sortData(data).map((item) => (
                <TreeItem
                  key={generateID()}
                  itemId={"parent_" + generateID()}
                  label={item.name || "Other"}
                >
                  {item.children.map((child) => (
                    <TreeItem
                      key={generateID()}
                      itemId={"child_" + generateID()}
                      label={child.name}
                    >
                      {child.rlsList.length > 0 && (
                        <span className="tree-data-subtitle">Radar / РЛС:</span>
                      )}
                      {child.rlsList.map((rls) => (
                        <TreeItem
                          key={generateID()}
                          itemId={"rls_" + generateID()}
                          label={rls.name}
                          onClick={() => {
                            handleItemClick(rls, child);
                          }}
                        />
                      ))}
                      {child.rockets.length > 0 && (
                        <span className="tree-data-subtitle">
                          Missile / Ракета:
                        </span>
                      )}
                      {child.rockets.map((rocket) => (
                        <TreeItem
                          key={generateID()}
                          itemId={"rocket_" + generateID()}
                          label={rocket.name}
                          onClick={() => {
                            handleItemClick(rocket, child);
                          }}
                        />
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </SimpleTreeView>
          )}
        </div>
      </div>
      <div
        className={
          !isSideCollapsed
            ? "controls-side-toggle controls-side-toggle-collapsed"
            : "controls-side-toggle"
        }
        onClick={() => setIsSideCollapsed(!isSideCollapsed)}
      >
        {isSideCollapsed && <i className="fa-solid fa-chevron-left"></i>}
        {!isSideCollapsed && <i className="fa-solid fa-chevron-right"></i>}
      </div>
    </div>
  );
});

export default TreeNav;
