import { useState, useEffect } from "react";
import { authFetch, useAuth, logout } from "../providers/authProvider";
import { decodeToken } from "react-jwt";
import { Modal } from "react-responsive-modal";

function User() {
  const [token, setToken] = useState(null);
  const [logged, session] = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeIcon = <i className="fa-solid fa-xmark"></i>;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const API = "https://vps64209.hyperhost.name:3005/api/v1";
  // const API = "http://localhost:3005/api/v1";

  useEffect(() => {
    if (session && session.accessToken) {
      setToken(decodeToken(session.accessToken));
      const decodedToken = decodeToken(session.accessToken);
      if (decodedToken) {
        setToken(decodedToken);
        setUserRole(decodedToken.role);
      }
    }
  }, [session]);

  function updatePassword(password) {
    return authFetch(`${API}/users/${token?.sub}/password`, {
      method: "PUT",
      body: JSON.stringify({
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  const handleChangePass = () => {
    if (newPassword === confirmPassword) {
      setError("");
      updatePassword(newPassword)
        .then((response) => {
          logout();
          if (response.status === 200) {
            console.log("Password changed successfully");
          } else {
            console.error("Error changing password");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setError("Passwords don't match");
    }
  };

  const handleClearStoredMarkers = () => {
    sessionStorage.removeItem("markers");
    window.location.reload();
  };

  return (
    <>
      <div
        className={
          isDropdownOpen ? "dropdown is-active is-right" : "dropdown is-right"
        }
      >
        <div
          className="dropdown-trigger"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <button className="button is-link is-light has-text-weight-medium pt-3 pb-3">
            <i className="fa-solid fa-user"></i>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <div className="user-info pl-4 pr-4">
              <i className="fa-regular fa-user mr-2"></i> Hello,{" "}
              {token?.username || "user"}!
            </div>
            {userRole !== "User" && (
              <a href="/admin" className="dropdown-item pt-2 pb-2">
                <i className="fa-solid fa-star mr-2 has-text-warning"></i> Admin
                Panel
              </a>
            )}
            <a
              href="#"
              onClick={() => {
                setIsModalOpen(true);
                setIsDropdownOpen(false);
              }}
              className="dropdown-item pt-2 pb-2"
            >
              <i className="fa-solid fa-key mr-2 has-text-info"></i> Change
              Password
            </a>
            <a
              href="#"
              onClick={() => {
                handleClearStoredMarkers();
              }}
              className="dropdown-item pt-2 pb-2"
            >
              <i className="fa-solid fa-eraser mr-2 has-text-link"></i> Reset
              Map
            </a>
            <a
              href="#"
              onClick={() => {
                setIsDropdownOpen(false);
                logout();
              }}
              className="dropdown-item has-text-danger pt-2 pb-2"
            >
              <i className="fa-solid fa-right-from-bracket mr-2"></i> Log Out
            </a>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeOnOverlayClick={false}
        closeIcon={closeIcon}
      >
        <h5 className="subtitle is-5 modal-title">Change password</h5>

        <form>
          <div className="field">
            <label className="label">New password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="New password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Confirm new password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Confirm new password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>
          </div>

          {error && (
            <div>
              <span className="tag is-danger is-light mt-2 mb-2">
                <i className="fa-solid fa-triangle-exclamation mr-2"></i> New
                password and Confirm new password doesn't match!
              </span>
            </div>
          )}

          <span className="tag is-link mt-2 mb-2">
            <i className="fa-solid fa-circle-exclamation mr-2"></i> After
            changing password, you will be Logged Off, and you need to Log In
            again using new password
          </span>

          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                type="button"
                className="button is-info has-text-white"
                onClick={() => {
                  handleChangePass();
                }}
              >
                Change password
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default User;
