import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./components/Main";
import Admin from "./components/Admin";
import Login from "./components/Login";
import { useAuth } from "./providers/authProvider";
import { decodeToken } from "react-jwt";

const routerAdmin = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: '/:id',
    element: <Main />,
  },
  {
    path: "*",
    element: <Main />,
  },
]);

const routerUser = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: '/:id',
    element: <Main />,
  },
  {
    path: "*",
    element: <Main />,
  },
]);

const loginRouter = createBrowserRouter([
  {
    path: "*",
    element: <Login />,
  },
]);

function App() {
  const [logged, session] = useAuth();
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (session && session.accessToken) {
      const decodedToken = decodeToken(session.accessToken);
      if (decodedToken) {
        setToken(decodedToken);
        setUserRole(decodedToken.role);
      }
    }
  }, [session]);

  if (!logged || !session.accessToken || !session.refreshToken) {
    return <RouterProvider router={loginRouter} />;
  }

  if (userRole !== "User") {
    return <RouterProvider router={routerAdmin} />;
  }

  return <RouterProvider router={routerUser} />;
}

export default App;